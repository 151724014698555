import * as actionTypes from './actionTypes';
import axios from '../../axios-public';

export const ajaxLoading = error => ({
  type: actionTypes.MANGA_AJAX_LOADING
});

export const ajaxFail = error => ({
  type: actionTypes.MANGA_AJAX_FAIL,
  error
});

export const fetchList = data => ({
  type: actionTypes.MANGA_FETCH_LIST_SUCCESS,
  list: data
});

export const fetchSingle = data => ({
  type: actionTypes.MANGA_FETCH_SINGLE_SUCCESS,
  single: data
});

export const fetchMangaList = () => (dispatch) => {
  dispatch(ajaxLoading());
  axios.get('manga')
    .then((response) => {
      dispatch(fetchList(response.data.data));
    })
    .catch((err) => {
      dispatch(ajaxFail(err.response.data.message));
    });
};

export const fetchMangaSingle = slug => (dispatch) => {
  dispatch(ajaxLoading());
  axios.get(`manga/${slug}`)
    .then((response) => {
      dispatch(fetchSingle(response.data.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(ajaxFail('error'));
    });
};
