export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});

export const time = () => new Date();

export const isInt = value => !isNaN(value) &&
         parseInt(Number(value), 10) === value &&
         !isNaN(parseInt(value, 10));

export const hasNumber = check => /\d/.test(check);

export const hasAlphabet = check => /[a-zA-Z]/.test(check);

export const hasUpperLetter = check => /[A-Z]/.test(check);

export const hasLowerLetter = check => /[a-z]/.test(check);

export const getQueryStringParams = query => (query
  ? (/^[?#]/.test(query) ? query.slice(1) : query)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      return params;
    }, {})
  : {});

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required) {
    if (!isInt(value)) {
      if (Number.isNaN(value)) {
        value = '';
      }
      isValid = value.trim() !== '' && isValid;
    }
  } else if (!rules.required && rules.required === false && value.trim() === '') {
    return true;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.min) {
    isValid = value >= rules.min && isValid;
  }

  if (rules.max) {
    isValid = value <= rules.max && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.isEmail) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  if (rules.containsDigits) {
    isValid = hasNumber(value) && isValid;
  }

  if (rules.containsAlphabet) {
    isValid = hasAlphabet(value) && isValid;
  }

  if (rules.containsUpperLetter) {
    isValid = hasUpperLetter(value) && isValid;
  }

  if (rules.containsLowerLetter) {
    isValid = hasLowerLetter(value) && isValid;
  }

  return isValid;
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const formInputChangedHandler = (controls, inputIdentifier, newValue) => {
  const updatedControl = {
    ...controls
  };

  const updatedControlElement = {
    ...updatedControl[inputIdentifier]
  };
  updatedControlElement.value = newValue;
  updatedControlElement.valid = checkValidity(newValue, updatedControlElement.validation);
  updatedControlElement.touched = true;
  updatedControl[inputIdentifier] = updatedControlElement;

  let formIsValid = true;
  for (const field in updatedControl) {
    formIsValid = updatedControl[field].valid && formIsValid;
  }

  return { controls: updatedControl, formIsValid };
};

export const validateControlsForm = (controls) => {
  const updatedControls = {
    ...controls
  };
  for (const formElementIdentifier in controls) {
    updatedControls[formElementIdentifier] = {
      ...controls[formElementIdentifier],
      touched: true
    };
  }

  return updatedControls;
};