import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';

const initialState = {
  list: [],
  article: null,
  error: null,
  loading: false
};

const ajaxLoading = (state, action) => updateObject(state, {
  loading: true,
  error: null
});

const ajaxFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.error
});

const fetchList = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  list: action.list
});

const fetchSingle = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  article: action.article
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ARTICLES_FETCH_LIST_SUCCESS: return fetchList(state, action);
    case actionTypes.ARTICLES_FETCH_SINGLE_SUCCESS: return fetchSingle(state, action);
    case actionTypes.ARTICLES_AJAX_LOADING: return ajaxLoading(state, action);
    case actionTypes.ARTICLES_AJAX_FAIL: return ajaxFail(state, action);
    default: return state;
  }
};

export default reducer;
