import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Progress } from 'reactstrap';
import axios from '../../../../../axios-public';

class ScoreStats extends Component {
  constructor (props) {
    super(props);
    this.state = {
      scores: null,
      percents: null
    };
  }

  componentDidMount () {
    if (this.props.anime) {
      this.fetchData();
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.anime !== prevProps.anime) {
      this.fetchData();
    }
  }

  fetchData () {
    axios.get(`anime/${this.props.anime.id}/score`)
      .then((res) => {
        const data = res.data.data;
        let total = 0;

        Object.keys(data).forEach((key) => {
          total += data[key];
        });

        const copy = { ...res.data.data };

        Object.keys(data).forEach((key) => {
          copy[key] = Math.round(data[key] / total * 100);
        });

        this.setState({
          scores: res.data.data,
          percents: copy
        });
      });
  }

  render () {
    const scores = [];

    if (this.state.scores) {
      for (let i = 10; i > 0; i -= 1) {
        scores.push((
          <Row key={i} className="font-size-14">
            <Col lg={3} md={5} sm={6}>
              {`Balas ${i} (${this.state.scores[i]})`}
            </Col>
            <Col lg={9} md={7} sm={6}>
              <Progress key={i} className="mt5" value={this.state.percents[i]}>
                {this.state.percents[i]}
%
              </Progress>
            </Col>
          </Row>
        ));
      }
    }

    return scores;
  }
}

ScoreStats.propTypes = {
  anime: PropTypes.any.isRequired
};

export default ScoreStats;
