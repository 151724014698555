import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';

const initialState = {
  token: null,
  userId: null,
  username: null,
  username_canonical: null,
  design: 'white',
  role: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  recovery: null,
  email: null,
  success: false,
  premium: false,
  notifications: [],
  displayArticles: true,
  displayWishlist: false,
  displayWatching: false,
  displayGenres: false,
  mal: [],
  member: 'Narys',
  member_until: '2022-06-01',
  level: 1
};

const authStart = (state, action) => updateObject(state, { loading: true, error: null, success: false });

const ajaxLoading = (state, action) => updateObject(state, { loading: true, error: null, success: false });

const notifications = (state, action) => updateObject(state, { notifications: action.notifications });

const mal = (state, action) => updateObject(state, { mal: action.list });

const level = (state, action) => updateObject(state, { level: action.level });

const seenNofitcationMark = (state, action) => {
  const notificationToRemove = [...state.notifications];
  const removedEntity = notificationToRemove.find(notification => notification.id === action.seen);
  const updatedNotifications = notificationToRemove.filter(notification => notification.id !== action.seen);
  const updatedNotifications2 = updatedNotifications.filter((notification) => {
    if (notification.type !== 'notification') {
      return true;
    }
    return notification.entity_id !== removedEntity.entity_id;
  });
  return updateObject(state, { notifications: updatedNotifications2 });
};

const authSuccess = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  token: action.token,
  userId: action.userId,
  role: action.role,
  design: action.design,
  username: action.username,
  username_canonical: action.username_canonical,
  email: null,
  recovery: false,
  success: true,
  displayArticles: action.displayArticles,
  displayWishlist: action.displayWishlist,
  displayWatching: action.displayWatching,
  displayGenres: action.displayGenres,
  premium: action.premium,
  member: action.member || 'Narys',
  member_until: action.member_until || '2022-06-01'
});

const passwordRecoveryRequested = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  recovery: true,
  email: action.email
});

const passwordUpdated = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  token: action.token
});

const authFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.error,
  userId: null,
  token: null,
  success: false,
  premium: false
});

const ajaxFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.error,
  success: false
});

const authLogout = (state, action) => updateObject(state, {
  token: null,
  userId: null,
  username: null,
  design: 'white',
  role: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  success: false,
  premium: false
});

const setAuthRedirectPath = (state, action) => updateObject(state, { authRedirectPath: action.path });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_AJAX_FAIL: return ajaxFail(state, action);
    case actionTypes.AUTH_AJAX_START: return ajaxLoading(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
    case actionTypes.AUTH_PASSWORD_UPDATED: return passwordUpdated(state, action);
    case actionTypes.AUTH_PASSWORD_RECOVERY_SENT: return passwordRecoveryRequested(state, action);
    case actionTypes.AUTH_USER_NOTIFICATIONS: return notifications(state, action);
    case actionTypes.AUTH_USER_SEEN_MARK: return seenNofitcationMark(state, action);
    case actionTypes.AUTH_USER_MAL: return mal(state, action);
    case actionTypes.AUTH_USER_LEVEL: return level(state, action);
    default: return state;
  }
};

export default reducer;
