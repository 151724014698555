export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_AJAX_START = 'AUTH_AJAX_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_PASSWORD_UPDATED = 'AUTH_PASSWORD_UPDATED';
export const AUTH_PASSWORD_RECOVERY_SENT = 'AUTH_PASSWORD_RECOVERY_SENT';
export const AUTH_AJAX_FAIL = 'AUTH_AJAX_FAIL';
export const AUTH_USER_NOTIFICATIONS = 'AUTH_USER_NOTIFICATIONS';
export const AUTH_USER_SEEN_MARK = 'AUTH_USER_SEEN_MARK';
export const AUTH_USER_MAL = 'AUTH_USER_MAL';
export const AUTH_USER_LEVEL = 'AUTH_USER_LEVEL';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const ARTICLES_AJAX_LOADING = 'ARTICLES_AJAX_LOADING';
export const ARTICLES_AJAX_FAIL = 'ARTICLES_AJAX_FAIL';
export const ARTICLES_FETCH_LIST_SUCCESS = 'ARTICLES_FETCH_LIST_SUCCESS';
export const ARTICLES_FETCH_SINGLE_SUCCESS = 'ARTICLES_FETCH_SINGLE_SUCCESS';

export const ANIME_AJAX_LOADING = 'ANIME_AJAX_LOADING';
export const ANIME_AJAX_FAIL = 'ANIME_AJAX_FAIL';
export const ANIME_FETCH_LIST_SUCCESS = 'ANIME_FETCH_LIST_SUCCESS';
export const ANIME_FETCH_LIST_SUCCESS_SILENT = 'ANIME_FETCH_LIST_SUCCESS_SILENT';
export const ANIME_FETCH_SINGLE_SUCCESS = 'ANIME_FETCH_SINGLE_SUCCESS';
export const ANIME_FETCH_SEARCH_LIST = 'ANIME_FETCH_SEARCH_LIST';

export const MANGA_AJAX_LOADING = 'MANGA_AJAX_LOADING';
export const MANGA_AJAX_FAIL = 'MANGA_AJAX_FAIL';
export const MANGA_FETCH_LIST_SUCCESS = 'MANGA_FETCH_LIST_SUCCESS';
export const MANGA_FETCH_SINGLE_SUCCESS = 'MANGA_FETCH_SINGLE_SUCCESS';

export const CMS_AJAX_LOADING = 'CMS_AJAX_LOADING';
export const CMS_AJAX_FAIL = 'CMS_AJAX_FAIL';
export const CMS_FETCH_LIST = 'CMS_FETCH_LIST';
export const CMS_FETCH_SINGLE = 'CMS_FETCH_SINGLE';

export const SHOUTBOX_REFRESH = 'SHOUTBOX_REFRESH';
export const SHOUTBOX_ADD_MSG = 'SHOUTBOX_ADD_MSG';
export const SHOUTBOX_NOTHING = 'SHOUTBOX_NOTHING';
