import React from 'react';
import {
  FormGroup, Input, Label, Badge
} from 'reactstrap';
import Translate from 'translate-components';

// const genres = {
//   Action: 0,
//   Adventure: 0,
//   Comedy: 0,
//   Drama: 0,
//   Ecchi: 0,
//   Fantasy: 0,
//   Game: 0,
//   Harem: 0,
//   Hentai: 0,
//   Historical: 0,
//   Horror: 0,
//   Magic: 0,
//   'Martial Arts': 0,
//   Mecha: 0,
//   Military: 0,
//   Music: 0,
//   Mystery: 0,
//   Parody: 0,
//   Psychological: 0,
//   Police: 0,
//   Romance: 0,
//   Samurai: 0,
//   School: 0,
//   SciFi: 0,
//   Seinen: 0,
//   Shoujo: 0,
//   Shounen: 0,
//   'Slice of Life': 0,
//   Space: 0,
//   Sports: 0,
//   'Super Power': 0,
//   Supernatural: 0,
//   Swordplay: 0,
//   Thriller: 0,
//   Tragedy: 0,
//   Vampire: 0,
//   Voilence: 0,
//   Isekai: 0,
//   Josei: 0
// };

const filter = props => props.counts.map(genre => (
  <FormGroup check key={genre.genre}>
    <Label check>
      <Input checked={props.filtered.includes(genre.genre)} type="checkbox" value={genre.genre} onChange={event => props.change(event)} />
      {' '}
      <Translate>{genre.genre}</Translate>
      {' '}
      <Badge>{genre.total}</Badge>
    </Label>
  </FormGroup>
));

export default filter;
