import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Form, Input, Label, Button
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import styles from './AnimeSearch.module.css';
import AnimeResultItem from '../../components/Anime/Search/Item';
import AnimeResultGenres from '../../components/Anime/Search/Genres';
import Spinner from '../../components/UI/Spinner/Spinner';
import Helmet from '../../components/Helmet/Helmet';
import axios from '../../axios-public';
import Aux from '../../hoc/Aux/Aux';

class AnimeSearch extends Component {
  constructor (props) {
    super(props);
    this.toggleGenresBtn = this.toggleGenresBtn.bind(this);
    this.state = {
      items: [],
      results: [],
      type: '',
      name: '',
      genres: [],
      filterGenres: [],
      loading: true,
      showGenres: window.innerWidth > 770,
      isDesktop: window.innerWidth > 770
    };
  }

  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search);
    const genres = params.get('genre') ? [params.get('genre')] : [];
    let name = params.get('name') || '';
    this.setState({
      name, genres
    });
    this.fetchResults(name, genres, this.state.type)
  }

  fetchResults(altName, genres, type) {
    const name = altName.toLowerCase();
    const genre = genres.join(",");
    this.setState({
      name,
      loading: true
    });
    axios.get(`anime/search?q=${name}&type=${type}&genres=${genre}`)
    .then((res) => {
      this.setState({
        loading: false, 
        results: res.data.data.sort(this.compare),
        genres: res.data.byGenres
      });
    })
    .catch((err) => {
      this.setState({
        loading: false,
      });
      console.log('err', err);
    });
  }

  componentDidUpdate (prevProps, prevState) {
    const searchNotMatch = prevProps.location.search !== this.props.location.search;
    const searchTextPresent = this.props.location.search.includes("name=") && this.props.location.search.length > 1 && this.state.name === '';
    if (!this.state.loading && (searchNotMatch || searchTextPresent)) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        console.log("time out");
        const params = new URLSearchParams(this.props.location.search);
        let name = params.get('name') || '';
        this.fetchResults(name.toLowerCase(), this.state.filterGenres, this.state.type);
      }, 1600);
    }
  }

  toggleGenresBtn (e) {
    this.setState({ showGenres: !this.state.showGenres });
  }

  compare (a, b) {
    if (a.malScore > b.malScore) { return -1; }
    if (a.malScore < b.malScore) { return 1; }
    return 0;
  }

  inputChangedHandler (event) {
    const type = event.target.value.toLowerCase();
    this.setState({ type });
    this.fetchResults(this.state.name, this.state.filterGenres, type);
  }

  handleCheck (event) {
    const updatedGenres = [...this.state.filterGenres];
    const index = this.state.filterGenres.indexOf(event.target.value);
    if (index > -1) {
      updatedGenres.splice(index, 1);
    } else {
      updatedGenres.push(event.target.value);
    }
    this.setState({ filterGenres: updatedGenres });
    this.fetchResults(this.state.name, updatedGenres, this.state.type);
  }

  render () {
    let main = <Spinner />;
    let gendresField = null;
    let toggleGenresBtn = null;
    let redirect = null;

    if (this.state.showGenres) {
      gendresField = <AnimeResultGenres 
                      filtered={this.state.filterGenres} 
                      anime={this.state.results} 
                      counts={this.state.genres} 
                      change={event => this.handleCheck(event)} 
                    />;
    }

    if (!this.state.isDesktop) {
      toggleGenresBtn = <Button color="info" onClick={e => this.toggleGenresBtn()} block size="sm" style={{ margin: '10px 0px' }}>Filtruoti Žanrus</Button>;
    }

    if (!this.state.loading && this.state.results.length === 1) {
      redirect = <Redirect to={this.state.results[0].url} />;
    } else if (!this.state.loading && this.state.results.length > 0) {
      const onlyTwo = this.state.results.length === 2;
      main = this.state.results.slice(0, 60).map(anime => <AnimeResultItem key={anime.id} anime={anime} onlyTwo={onlyTwo} />);
    } else if (!this.state.loading && this.state.results.length === 0) {
      main = <h2>Ups. Nėra rezultatų.</h2>
    }

    return (
      <Aux>
        {redirect}
        <Helmet title="Anime Paieška">
          <meta name="robots" content="INDEX, FOLLOW" />
        </Helmet>
        <Row>
          <Col sm={12}>
            <h3 className="headline p5 clearfix">Paieška</h3>
          </Col>
          <Col md={2} sm={4}>
            <Form>
              <Label>Tipas</Label>
              <Input
                type="select"
                value={this.state.type}
                className={styles.dropdown}
                id="type"
                onChange={event => this.inputChangedHandler(event)}
              >
                <option />
                <option value="filmas">Filmas</option>
                <option value="tv">TV</option>
                <option value="ova">OVA</option>
                <option value="special">Special</option>
              </Input>
              {gendresField}
              {toggleGenresBtn}
            </Form>
          </Col>
          <Col md={10} sm={8} className="search">
            <Row>
              {main}
            </Row>
          </Col>
        </Row>
      </Aux>
    );
  }
}

AnimeSearch.propTypes = {
  location: PropTypes.any
};

AnimeSearch.defaultProps = {
  location: undefined
};

export default AnimeSearch;
