import React from 'react';
import { IoIosStarHalf, IoIosStarOutline, IoIosStar } from 'react-icons/io';

const RatingStars = (props) => {
  const fullStars = Math.round(props.score / 2);
  const stars = [];
  for (let i = 0; i < fullStars; i += 1) {
    stars.push(<IoIosStar key={i} color="red" />);
  }
  if ((props.score % 2) > 1) {
    stars.push(<IoIosStarHalf key={6} color="red" />);
  }

  if (stars.length < 5) {
    const missing = 5 - stars.length;
    for (let i = 0; i < missing; i += 1) {
      stars.push(<IoIosStarOutline key={`e_${i}`} color="red" />);
    }
  }

  return stars;
};

export default RatingStars;
