import React from 'react';
import PropTypes from 'prop-types';
import Translate from 'translate-components';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image';
import ColoredLine from '../../../../UI/ColoredLine/ColoredLine';

const imageStyle = {
  width: '100%',
  maxWidth: '220px',
  maxHeight: '250px'
};

const Relationships = (props) => {
  if (props.items.length < 1) {
    return null;
  }

  return (
    <Row className="mt20">
      <Col sm="12">
        <h3>Susiję anime:</h3>
        <ColoredLine height={1} />
      </Col>
      {props.items.map((item) => {
        const picture = item.image;
        return (
          <Col sm={4}>
            <Link to={`/anime/${item.slug}`}>
              <p className="text-center" title={item.name}>{item.name.substring(0, 40)}</p>
              <Image
                style={imageStyle}
                src={picture}
                srcSet={[picture.replace('.webp', '.jpg')]}
                alt={item.name.replace(/[^0-9a-zA-Z ,]/g, '')}
              />
              <Row className="text-center text-color">
                <Col>
                  <Translate>{item.type}</Translate>
                </Col>
              </Row>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};

Relationships.propTypes = {
  items: PropTypes.array
};

Relationships.defaultProps = {
  items: []
};

export default Relationships;
