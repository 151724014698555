import React from 'react';
import PropTypes from 'prop-types';

const SidebarElement = (props) => {
  if (!props.value || props.value === '') {
    return null;
  }

  return (
    <span>
      <strong>{`${props.title}:`}</strong>
      {' '}
      <span className="highlight-text">{props.value}</span>
    </span>

  );
};

SidebarElement.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default SidebarElement;
