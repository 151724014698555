import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Row, Col
} from 'reactstrap';
import classnames from 'classnames';
import Spinner from '../../../UI/Spinner/Spinner';
import Aux from '../../../../hoc/Aux/Aux';
import BBCode from '../../../Comments/BBCode/BBCode';
import OnlineList from './OnlineList/OnlineList';
import Recommandations from './Recommandations/Recommandations';
import NewRecommendation from '../../AnimeRecommandations/NewRecommendation';
import ColoredLine from '../../../UI/ColoredLine/ColoredLine';
import ScoreStats from './ScoreStats/ScoreStats';
import Relationships from './Relationships';
import './Main.css';

class Main extends PureComponent {
  constructor (props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle (tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render () {
    let description = null;
    let onlineList = <Spinner />;
    let recommendations = <Spinner />;
    let newRecommendation = <Spinner />;
    let youtube = null;

    if (this.props.anime && !this.props.loading) {
      description = <BBCode>{this.props.anime.description}</BBCode>;
      onlineList = <OnlineList loading={this.props.loading} anime={this.props.anime} />;
      recommendations = <Recommandations anime={this.props.anime} />;
      newRecommendation = <NewRecommendation anime={this.props.anime} />;
    }

    if (!this.props.loading && this.props.anime.youtube && this.props.anime.youtube !== '') {
      youtube = (
        <div className="mt-16">
          <h5>Anonsas:</h5>
          <iframe
            src={this.props.anime.youtube.replace('watch?v=', 'embed/')}
            style={{ width: '100%', height: 'auto', minHeight: '300px' }}
            frameBorder="0"
            title="youtube video"
            allowFullScreen
          />
        </div>
      );
    }

    return (
      <Aux>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Aprašymas
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Vertinimas
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Rekomendacijos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Žiūrėti Online
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12" className="mt10">
                <h3>Aprašymas</h3>
                <ColoredLine height={1} />
              </Col>
              <Col sm="12">
                {description}
                {youtube}
                <Relationships items={this.props.anime.relationships} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12" className="mt10">
                <h3>Vertinimas</h3>
                <ColoredLine height={1} />
              </Col>
              <Col sm="12">
                <ScoreStats anime={this.props.anime} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12" className="mt10">
                <h3>Rekomendacijos</h3>
                <ColoredLine height={1} />
              </Col>
              <Col sm="12">
                {newRecommendation}
                {recommendations}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12" className="mt10">
                <h3>Žiūrėti Online</h3>
                <ColoredLine height={1} />
              </Col>
              <Col sm="12">
                {onlineList}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Aux>
    );
  }
}

Main.propTypes = {
  anime: PropTypes.any.isRequired,
  loading: PropTypes.bool
};

Main.defaultProps = {
  loading: true
};

export default Main;
