import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Spinner from '../../../../UI/Spinner/Spinner';
import axios from '../../../../../axios-public';

class OnlineList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      list: null,
      loading: true
    };
  }

  componentDidMount () {
    this.fetchList();
  }

  componentDidUpdate (prevProps) {
    if (this.props.anime.id !== prevProps.anime.id) {
      this.fetchList();
    }
  }

  fetchList () {
    this.setState({ loading: true, list: null });
    axios.get(`online/${this.props.anime.id}/list`)
      .then((res) => {
        this.setState({ list: res.data.data, loading: false });
      });
  }

  render () {
    if (this.state.loading || this.props.loading) {
      return <Spinner />;
    }

    let subList = <Col sm={12}>Nėra</Col>;
    let audioList = <Col sm={12}>Nėra</Col>;

    if (this.state.list && this.state.list.subs) {
      subList = this.state.list.subs.map((obj, index) => (
        <Col sm={12} key={`subs_${index}`}>
          <Link to={`/online/${obj.slugifyUrl}/ep${obj.ep}-${obj.type}`}>
            {`${obj.name} - ${obj.ep} dalis`}
          </Link>
        </Col>
      ));
    }

    if (this.state.list && this.state.list.audio) {
      audioList = this.state.list.audio.map((obj, index) => (
        <Col sm={12} key={`audio_${index}`}>
          <Link to={`/online/${obj.slugifyUrl}/ep${obj.ep}-${obj.type}`}>
            {`${obj.name} - ${obj.ep} dalis`}
          </Link>
        </Col>
      ));
    }

    if (this.state.list && this.state.list.subs && !this.state.list.audio) {
      return (
        <Col sm={12}>
          <h3>Subtitruota</h3>
          {subList}
        </Col>
      );
    } if (this.state.list && this.state.list.audio && !this.state.list.subs) {
      return (
        <Col sm={12}>
          <h3>Įgarsinta</h3>
          {audioList}
        </Col>
      );
    }
    return (
      <Row>
        <Col sm={6}>
          <h3>Subtitruota</h3>
          {subList}
        </Col>
        <Col sm={6}>
          <h3>Įgarsinta</h3>
          {audioList}
        </Col>
      </Row>
    );
  }
}

OnlineList.propTypes = {
  anime: PropTypes.any.isRequired,
  loading: PropTypes.bool
};

OnlineList.defaultProps = {
  loading: true
};

export default OnlineList;
