import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image';
import axios from '../../../../../axios-public';
import ColoredLine from '../../../../UI/ColoredLine/ColoredLine';
import Spinner from '../../../../UI/Spinner/Spinner';

class Recommandations extends Component {
  constructor (props) {
    super(props);
    this.state = {
      list: null,
      loading: true
    };
  }

  componentDidMount () {
    this.fetchList();
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.state.list !== nextState.list ||
               this.state.loading !== nextProps.loading ||
               this.props.anime.id !== nextProps.anime.id;
  }

  componentDidUpdate (prevProps) {
    if (this.props.anime.id !== prevProps.anime.id) {
      this.fetchList();
    }
  }

  fetchList () {
    this.setState({ loading: true });
    axios.get(`anime/${this.props.anime.id}/recommendations`)
      .then((response) => {
        this.setState({ list: response.data.data, loading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render () {
    let recommendation = null;

    if (this.state.loading) {
      return <Spinner />;
    }

    if (this.state.list) {
      recommendation = this.state.list.map((obj) => {
        const animeRecommended = (obj.recommendation.id === this.props.anime.id) ? obj.anime : obj.recommendation;
        return (
          <div key={obj.id}>
            <Media>
              <Media left top>
                <Link to={animeRecommended.url} title={animeRecommended.name}>
                  <Image src={animeRecommended.image_url.replace('anime/', 'anime/thumb/')} alt={animeRecommended.name} style={{ maxWidth: '80px' }} />
                </Link>
              </Media>
              <Media body style={{ whiteSpace: 'pre-line', paddingLeft: '10px' }}>
                <Media middle>
                  <Link to={animeRecommended.url} title={animeRecommended.name}>{animeRecommended.name}</Link>
                  {' | Rekomendavo: '}
                  <Link to={obj.user.profile_url} title={obj.user.username}>{obj.user.username}</Link>
                </Media>
                <p>{obj.comment}</p>
              </Media>
            </Media>
            <ColoredLine height={1} />
          </div>
        );
      });
    }

    return (
      <Row>
        <Col sm={12} className="mt5">
          {recommendation}
        </Col>
      </Row>
    );
  }
}

Recommandations.propTypes = {
  anime: PropTypes.any.isRequired,
  loading: PropTypes.bool
};

Recommandations.defaultProps = {
  loading: true
};

export default Recommandations;
