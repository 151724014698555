import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form, Label, Input, FormGroup, InputGroup, InputGroupAddon, Button, Col, Row
} from 'reactstrap';
import { checkValidity } from '../../../../shared/utility';
import axios from '../../../../axios-public';
import Aux from '../../../../hoc/Aux/Aux';
import Spinner from '../../../UI/Spinner/Spinner';

class AnimeMAlForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      controls: {
        status: {
          value: '',
          validation: {
            required: true,
            min: 0
          },
          valid: false,
          touched: false
        },
        progress: {
          value: 0,
          validation: {
            required: true,
            min: 0,
            max: 999,
            isInt: true
          },
          valid: true,
          touched: true
        },
        score: {
          value: 8,
          validation: {
            required: true,
            min: 0,
            isInt: true
          },
          valid: true,
          touched: true
        }
      },
      formIsValid: false,
      loading: true,
      submited: false
    };

    this.inputChangedHandler = this.inputChangedHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.sliderHandler = this.sliderHandler.bind(this);
  }

  componentDidMount () {
    this.fetchMyMal();
  }

  componentDidUpdate (prevProps) {
    if (this.props.anime.id !== prevProps.anime.id) {
      this.setState({ loading: true });
      this.fetchMyMal();
    }
  }

  maxEp (event) {
    const value = this.props.anime.series;
    const inputIdentifier = 'progress';

    const updatedControls = {
      ...this.state.controls,
      [inputIdentifier]: {
        ...this.state.controls[inputIdentifier],
        value,
        valid: checkValidity(value, this.state.controls[inputIdentifier].validation),
        touched: true
      }
    };

    let formIsValid = true;
    for (const field in updatedControls) {
      formIsValid = updatedControls[field].valid && formIsValid;
    }

    this.setState({ controls: updatedControls, formIsValid });
  }

  inputChangedHandler (event) {
    const inputIdentifier = event.target.id;
    let value = event.target.value;
    if (inputIdentifier !== 'status') {
      value = value.replace(/\b0+/g, '');
    }
    if (inputIdentifier === 'score') {
      value = parseInt(value, 10);
      if (value > 10) {
        value = 10;
      }
    }

    const updatedControls = {
      ...this.state.controls,
      [inputIdentifier]: {
        ...this.state.controls[inputIdentifier],
        value,
        valid: checkValidity(value, this.state.controls[inputIdentifier].validation),
        touched: true
      }
    };

    let formIsValid = true;
    for (const field in updatedControls) {
      formIsValid = updatedControls[field].valid && formIsValid;
    }

    this.setState({ controls: updatedControls, formIsValid });
  }

  submitHandler (event) {
    event.preventDefault();
    if (!this.state.formIsValid) {
      const updatedControls = {
        ...this.state.controls
      };
      for (const formElementIdentifier in this.state.controls) {
        updatedControls[formElementIdentifier] = {
          ...this.state.controls[formElementIdentifier],
          touched: true
        };
      }
      this.setState({ controls: updatedControls });
      return;
    }

    const data = {
      status: parseInt(this.state.controls.status.value, 10),
      progress: parseInt(this.state.controls.progress.value, 10),
      score: parseInt(this.state.controls.score.value, 10)
    };

    axios.post(`anime/${this.props.anime.id}/me/mal`, data)
      .then((res) => {
        if (res.data.message === 'success') {
          this.setState({ submited: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  sliderHandler (value) {
    const updatedControls = {
      ...this.state.controls,
      score: {
        ...this.state.controls.score,
        value,
        valid: checkValidity(value, this.state.controls.score.validation),
        touched: true
      }
    };

    let formIsValid = true;
    for (const field in updatedControls) {
      formIsValid = updatedControls[field].valid && formIsValid;
    }

    this.setState({ controls: updatedControls, formIsValid });
  }

  fetchMyMal () {
    if (!this.props.isAuth) {
      return;
    }
    const url = `anime/${this.props.anime.id}/me/mal`;
    axios.get(url)
      .then((res) => {
        const data = res.data.data;
        const controls = { ...this.state.controls };
        let formIsValid = false;

        controls.progress = { ...this.state.controls.progress };
        controls.score = { ...this.state.controls.score };
        controls.status = { ...this.state.controls.status };
        controls.progress.valid = true;
        controls.score.valid = true;
        controls.score.touched = true;
        controls.progress.validation = { ...this.state.controls.progress.validation, max: this.props.anime.series };

        if (res.data.data) {
          controls.progress.value = data.progress;
          controls.progress.touched = true;
          controls.score.value = data.score;
          controls.status.value = data.status;
          controls.status.valid = true;
          controls.status.touched = true;
          formIsValid = true;
        } else {
          controls.progress.value = 0;
          controls.progress.touched = false;
          controls.score.value = 0;
          controls.status.value = '';
          controls.status.valid = false;
          controls.status.touched = false;
        }

        this.setState({
          loading: false, controls, formIsValid, submited: false
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }

  render () {
    if (!this.props.isAuth) {
      return null;
    }
    if (this.state.loading) {
      return <Spinner />;
    }

    let eps = '?';

    if (this.props.anime.series) {
      eps = this.props.anime.series;
    }

    let form = 'Priimta informacija.';

    if (!this.state.submited) {
      form = (
        <Form onSubmit={this.submitHandler}>
          <h4 className="highlight-text">Mano MAL</h4>
          <FormGroup>
            <Row>
              <Col xs={6} sm={6} md={4}>
                <Label>Buklė</Label>
                <Input
                  type="select"
                  id="status"
                  name="status"
                  bsSize="sm"
                  value={this.state.controls.status.value}
                  invalid={!this.state.controls.status.valid && this.state.controls.status.touched}
                  onChange={e => this.inputChangedHandler(e)}
                >
                  <option>Būklė:</option>
                  <option value="0">Planuoju</option>
                  <option value="1">Žiūriu</option>
                  <option value="2">Mečiau</option>
                  <option value="3">Pristabdžiau</option>
                  <option value="4">Pabaigiau</option>
                </Input>
              </Col>
              <Col xs={6} sm={6} md={4}>
                <Label>Dalis</Label>
                <InputGroup size="sm">
                  <Input
                    bsSize="sm"
                    placeholder="Dalis"
                    type="number"
                    id="progress"
                    name="progress"
                    step="1"
                    value={parseInt(this.state.controls.progress.value, 10)}
                    invalid={!this.state.controls.progress.valid && this.state.controls.progress.touched}
                    onChange={e => this.inputChangedHandler(e)}
                  />
                  <InputGroupAddon
                    addonType="append"
                    onClick={e => this.maxEp(e)}
                  >
                    {`/${eps}`}

                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col sm={6} md={4} className="d-none d-sm-block">
                <Label>Balas</Label>
                <InputGroup size="sm">
                  <Input
                    bsSize="sm"
                    placeholder="score"
                    type="number"
                    id="score"
                    name="score"
                    step="1"
                    value={parseInt(this.state.controls.score.value, 10)}
                    invalid={!this.state.controls.score.valid && this.state.controls.score.touched}
                    onChange={e => this.inputChangedHandler(e)}
                  />
                  <InputGroupAddon
                    addonType="append"
                    onClick={e => this.maxEp(e)}
                  >
                    /10
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col xs={6} sm={6} md={4} className="d-block d-sm-none">
                <Label>Balas</Label>
                <Input
                  type="select"
                  placeholder="Įvertink"
                  name="score"
                  id="score"
                  bsSize="sm"
                  value={this.state.controls.score.value}
                  invalid={!this.state.controls.score.valid && this.state.controls.score.touched}
                  onChange={e => this.inputChangedHandler(e)}
                >
                  <option value="0">Įvertink...</option>
                  <option value="10">Nuostabus (10)</option>
                  <option value="9">Labai Geras (9)</option>
                  <option value="8">Geras (8)</option>
                  <option value="7">Neblogas (7)</option>
                  <option value="6">Pakenčiamas (6)</option>
                  <option value="5">Vidutinis (5)</option>
                  <option value="4">Prastas (4)</option>
                  <option value="3">Blogas (3)</option>
                  <option value="2">Labai blogas (2)</option>
                  <option value="1">Siaubingas (1)</option>
                </Input>
              </Col>
              <Col xs={6} sm={12} className="mobilePadddingLabel mt10">
                <Button size="sm" block outline color="success">Patvirtinti</Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      );
    }

    return (
      <Aux>
        {form}
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null
});

AnimeMAlForm.propTypes = {
  isAuth: PropTypes.bool,
  anime: PropTypes.any.isRequired
};

AnimeMAlForm.defaultProps = {
  isAuth: false
};

export default connect(mapStateToProps)(AnimeMAlForm);
