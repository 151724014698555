import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import Aux from '../../../hoc/Aux/Aux';
import Notification from './Notification';

class UnseenNotifications extends Component {
  constructor (props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      display: false
    };
  }

  componentDidMount () {
    this.props.onUnseenNofitcations();
    this.interval = setInterval(() => this.props.onUnseenNofitcations(), 15 * 60 * 1000); // every 15 mins
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  toggle () {
    this.setState(prevState => ({
      display: !prevState.display
    }));
  }

  render () {
    if (this.props.notifications.length === 0) {
      return null;
    }
    const notifications = this.props.notifications.filter(notification => !notification.seen && notification.type === 'notification');
    const chats = this.props.notifications.filter(notification => notification.type === 'chat');
    return (
      <Aux>
        <Dropdown direction="left" size="sm" isOpen={this.state.display} toggle={this.toggle}>
          <DropdownToggle onClick={this.toggle} tag="span" data-toggle="dropdown" aria-expanded={this.state.display}>
            <Badge
              pill
              color="secondary"
              onClick={e => this.toggle(e)}
              style={{
                height: '25px', width: '25px', lineHeight: '18px', marginTop: '8px', padding: '4px'
              }}
            >
              {notifications.length}
            </Badge>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header>Pranešimai</DropdownItem>
            {notifications.map(notification => <Notification key={notification.id} hasBeenSeen={this.props.onSeenNofitcation} notification={notification} />)}
            <DropdownItem header>Pokalbiai</DropdownItem>
            {chats.map(chat => <Notification key={chat.id} notification={chat} />)}
          </DropdownMenu>
        </Dropdown>

      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  notifications: state.auth.notifications
});

const mapDispatchToProps = dispatch => ({
  onUnseenNofitcations: () => dispatch(actions.unseenNofitcations()),
  onSeenNofitcation: id => dispatch(actions.seenNofitcation(id))
});

UnseenNotifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  onUnseenNofitcations: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnseenNotifications);
