import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';

const initialState = {
  messages: [],
  loadTime: 0
};

const refreshShoutbox = (state, action) => updateObject(state, {
  loading: false,
  messages: action.messages.reverse()
});

const addShoutboxMsg = (state, action) => {
  const msg = [...state.messages];
  msg.push(action.message);

  return updateObject(state, {
    loading: false,
    messages: msg
  });
};

const nothing = (state, action) => updateObject(state, {
  loading: false,
  loadTime: action.loadTime
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOUTBOX_REFRESH: return refreshShoutbox(state, action);
    case actionTypes.SHOUTBOX_ADD_MSG: return addShoutboxMsg(state, action);
    case actionTypes.SHOUTBOX_NOTHING: return nothing(state, action);
    default: return state;
  }
};

export default reducer;
