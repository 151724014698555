import React from 'react';
import PropTypes from 'prop-types';
import Translate from 'translate-components';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Image from 'react-graceful-image';
import SocialMAL from '../../Social/MAL';
import SocialAnimeDB from '../../Social/AnimeDB';
import AnimeMAlForm from './AnimeMAlForm';
import MalScore from '../MalScore';
import Aux from '../../../../hoc/Aux/Aux';
import SidebarElement from './SidebarElement';

const sidebar = (props) => {
  let img = null; // placeholder should be
  let type = null;
  let episodes = null;
  let year = null;
  let genres = [];
  let studios = [];
  let mal = null;
  let anidb = null;
  let malScore = 0;
  let animeMalForm = null;
  let duration = null;
  let ageRating = null;
  let source = null;
  let alternativeName = null;

  if (props.anime) {
    const picture = props.anime.image_url;
    img = <Image src={picture} srcSet={[picture.replace('.webp', '.jpg')]} alt={props.anime.name.replace(/[^0-9a-zA-Z ,]/g, '')} style={{ width: '100%' }} />;
    type = props.anime.type;
    episodes = props.anime.series;
    year = props.anime.year;

    if (props.anime.alternativeName) {
      alternativeName = (
        <Aux>
          <strong>Kitas pavadinimas:</strong>
          {' '}
          <span className="highlight-text">{props.anime.alternativeName}</span>
          <br />
        </Aux>
      );
    }
    if (props.anime.duration) {
      duration = (
        <Aux>
          <strong>Trukmė:</strong>
          {' '}
          <span className="highlight-text">{props.anime.duration}</span>
          <br />
        </Aux>
      );
    }
    if (props.anime.age_rating) {
      ageRating = (
        <Aux>
          <strong>Amžiaus grupė:</strong>
          {' '}
          <span className="highlight-text">{props.anime.age_rating}</span>
          <br />
        </Aux>
      );
    }
    if (props.anime.source && props.anime.source.length > 0) {
      source = (
        <Aux>
          <strong>Šaltinis:</strong>
          {' '}
          <span className="highlight-text">{props.anime.source}</span>
          <br />
        </Aux>
      );
    }

    genres = props.anime.genres.map(genre => (
      <Link key={genre} to={`/anime-paieska?genre=${genre}`}>
        <Translate>{genre}</Translate>
      </Link>
    ));
    if (genres.length > 0) {
      genres = genres.reduce((prev, curr) => [prev, ', ', curr]);
    }
    if (props.anime.studios) {
      studios = props.anime.studios.map((studio, index) => <Link key={index} to={`/anime/studija/${studio.slugifyUrl}`}>{studio.title}</Link>);
      if (studios.length > 0) {
        studios = studios.reduce((prev, curr) => [prev, ', ', curr]);
      }
    }

    mal = <SocialMAL id={props.anime.mal} />;
    anidb = <SocialAnimeDB id={props.anime.anidb} />;
    malScore = props.anime.malScore;

    animeMalForm = <AnimeMAlForm anime={props.anime} />;
  }

  let dalys = null;
  if (episodes && episodes > 1) {
    dalys = (
      <Aux>
        <strong>Dalys:</strong>
        {' '}
        <span className="highlight-text">{episodes}</span>
        <br />
      </Aux>
    );
  }

  return (
    <Row>
      <Col xs={4} sm={12}>
        {img}
      </Col>
      <Col xs={8} sm={12} className="desktop-mt-10">
        <p>
          {alternativeName}
          <SidebarElement title="Tipas" value={type} />
          {' '}
          {dalys}
          <SidebarElement title="Metai" value={year} />
          <br />
          <SidebarElement title="Studija" value={studios} />
          {source}
          {duration}
          {ageRating}
        </p>
      </Col>
      <Col sm={12}>
        <strong>Žanrai:</strong>
        {' '}
        {genres}
        <br />
        <strong>Papildomai:</strong>
        {' '}
        {mal}
        {' || '}
        {anidb}
        <p><MalScore anime={props.anime} malScore={malScore} /></p>
      </Col>
      <Col sm={12} style={{ marginBottom: '16px' }}>
        {animeMalForm}
      </Col>
    </Row>
  );
};

sidebar.propTypes = {
  anime: PropTypes.any.isRequired
};

export default sidebar;
