import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import ReactGA from 'react-ga';
import Aux from '../../../hoc/Aux/Aux';
import axios from '../../../axios-public';

class ThankYouButton extends PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      can: false
    };
    this.thankYou = this.thankYou.bind(this);
  }

  componentDidMount () {
    this.mounted = true;
    if (this.props.isAuth && this.props.online) {
      axios.post(`online/${this.props.online}/thanks`)
        .then((res) => {
          this.setState({ can: res.data.data === 'OK' });
        })
        .catch((err) => {
          this.setState({ can: false });
        });
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  thankYou () {
    axios.put(`online/${this.props.online}/thanks`)
      .then((res) => {
        if (this.mounted) {
          this.setState({ can: false });
          this.props.refresh();
        }
        ReactGA.event({
          category: 'Social',
          action: 'Click thank you btn'
        });
      })
      .catch((err) => {
        if (this.mounted) {
          this.setState({ can: false });
          this.props.refresh();
        }
      });
  }

  render () {
    if (!this.state.can) {
      return null;
    }
    return (
      <Aux>
        {'Jūs dar nepadėkojote! '}
        <Button color="info" outline size="xs" onClick={this.thankYou}>Padėkoti</Button>
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null
});

ThankYouButton.propTypes = {
  isAuth: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
  online: PropTypes.number.isRequired
};

ThankYouButton.defaultProps = {
  isAuth: false
};

export default connect(mapStateToProps)(ThankYouButton);
