import * as actionTypes from './actionTypes';
import axios from '../../axios-public';

export const ajaxLoading = error => ({
  type: actionTypes.CMS_AJAX_LOADING
});

export const ajaxFail = error => ({
  type: actionTypes.CMS_AJAX_FAIL,
  error
});

export const fetchListSuccess = data => ({
  type: actionTypes.CMS_FETCH_LIST,
  list: data
});

export const fetchSingleSuccess = data => ({
  type: actionTypes.CMS_FETCH_SINGLE,
  single: data
});

export const fetchCMSList = () => {
  const time = new Date();
  const cacheTime = new Date();
  cacheTime.setHours(cacheTime.getHours() + 6);

  if (typeof localStorage !== 'undefined') {
    const localJson = JSON.parse(localStorage.getItem('cmsList'));

    if (localJson && localJson.time < cacheTime.getTime()) {
      return (dispatch) => {
        dispatch(fetchListSuccess(localJson.cmsList));
      };
    }
  }

  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get('cms/navbar')
      .then((response) => {
        const content = {
          time: time.getTime(),
          cmsList: response.data.data
        };
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('cmsList', JSON.stringify(content));
        }
        dispatch(fetchListSuccess(response.data.data));
      })
      .catch((err) => {
        dispatch(ajaxFail(err));
      });
  };
};

export const fetchCMSSingle = (slug) => {
  const cacheTime = new Date();
  cacheTime.setHours(cacheTime.getHours() + 6);

  if (typeof localStorage !== 'undefined') {
    const localJson = JSON.parse(localStorage.getItem('cmsList'));
    if (localJson && localJson.time < cacheTime.getTime()) {
      return (dispatch) => {
        dispatch(ajaxLoading());
        const single = localJson.cmsList.find(cms => cms.link === slug);
        dispatch(fetchSingleSuccess(single));
      };
    }
  }

  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get('cms/navbar')
      .then((response) => {
        const single = response.data.data.find(cms => cms.link === slug);
        dispatch(fetchSingleSuccess(single));
      })
      .catch((err) => {
        dispatch(ajaxFail(err.response.data.message));
      });
  };
};
