import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Row, BreadcrumbItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions';
import Helmet from '../../components/Helmet/Helmet';
import Aux from '../../hoc/Aux/Aux';
import Spinner from '../../components/UI/Spinner/Spinner';
import AnimeInfoSidebar from '../../components/Anime/Info/Sidebar/Sidebar';
import AnimeInfoMain from '../../components/Anime/Info/Main/Main';
import asyncComponent from '../../components/AsyncComponent';
import { JsonBreadcumbLd } from '../../shared/JsonLd';

const Comments = asyncComponent(() => import('../../components/Comments/Comments'));

class AnimeView extends Component {
  componentDidMount () {
    this.props.onFetchAnimeSingle(this.props.match.params.slug);
  }

  componentDidUpdate (prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.props.onFetchAnimeSingle(this.props.match.params.slug);
    }
  }

  componentWillUnmount () {
    const wallpaper = document.getElementById('wallpaper');
    if (wallpaper) {
      wallpaper.style.background = 'none';
      wallpaper.style.size = 'cover';
    }
  }

  render () {
    let title = 'Anime';
    let comments = <Spinner />;
    let main = <Spinner />;
    let sidebar = <Spinner />;
    let helmet = null;
    let studio = null;
    const breadcrumbs = [];

    if (!this.props.loading && this.props.anime) {
      const description = `${this.props.anime.name} anime lietuviškai online, trumpai: ${this.props.anime.description}`;
      let subTitle = this.props.anime.name;
      if (this.props.anime.alternativeName) {
        subTitle += ` (${this.props.anime.alternativeName}) `;
      }
      helmet = (
        <Helmet title={`${subTitle} anime lietuviškai online`} description={description}>
          <meta property="og:type" content="website" />
          <meta property="og:image" content={this.props.anime.image_url} />
          <meta property="og:url" content={`https://animeonline.lt${this.props.location.pathname}`} />
          <meta name="robots" content="INDEX, FOLLOW" />
          <link rel="canonical" href={`https://animeonline.lt${this.props.location.pathname}`} />
        </Helmet>
      );

      title = subTitle;
      comments = <Comments id={this.props.anime.id} type="anime" />;
      main = <AnimeInfoMain anime={this.props.anime} loading={this.props.loading} />;
      sidebar = <AnimeInfoSidebar anime={this.props.anime} />;

      if (this.props.anime.studios.length > 0) {
        studio = (
          <BreadcrumbItem>
            <Link to={`/anime/studija/${this.props.anime.studios[0].slugifyUrl}`}>
              {this.props.anime.studios[0].title}
            </Link>
          </BreadcrumbItem>
        );
        breadcrumbs.push({
          '@type': 'ListItem',
          position: 2,
          name: this.props.anime.studios[0].title,
          item: `https://animeoneline.lt/${this.props.anime.studios[0].slugifyUrl}`
        });
      }
      breadcrumbs.push({
        '@type': 'ListItem',
        position: 3,
        name: title,
        item: `https://animeoneline.lt${this.props.location.pathname}`
      });

      const wallpaper = document.getElementById('wallpaper');
      const windowWidth = window.innerWidth;

      if (wallpaper && this.props.anime.wallpaper && windowWidth > 1000) {
        wallpaper.style.background = "url('https://animeonline.lt/images/anime/wallpaper/" + this.props.anime.slugifyUrl + ".jpg') no-repeat center center fixed";
        wallpaper.style.size = 'cover';
      } else if (wallpaper) {
        wallpaper.style.background = 'none';
        wallpaper.style.size = 'cover';
      }
    }

    return (
      <Aux>
        {helmet}
        <Row>
          <Col sm={12}>
            <h1 className="headline p3 clearfix">{title}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <BreadcrumbItem>
                  <Link to="/">Titulinis</Link>
                </BreadcrumbItem>
                {studio}
                <BreadcrumbItem active>
                  <Link to={this.props.location.pathname}>{title}</Link>
                </BreadcrumbItem>
              </ol>
            </nav>
          </Col>
          <Col md={4}>
            {sidebar}
          </Col>
          <Col md={8}>
            {main}
          </Col>
          <Col sm={12}>
            {comments}
          </Col>
        </Row>
        <JsonBreadcumbLd items={breadcrumbs} />
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.anime.loading,
  error: state.anime.error,
  anime: state.anime.anime
});

const mapDispatchToProps = dispatch => ({
  onFetchAnimeSingle: slug => dispatch(actions.fetchAnimeSingle(slug))
});

AnimeView.propTypes = {
  onFetchAnimeSingle: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.any.isRequired,
  location: PropTypes.any.isRequired,
  anime: PropTypes.any
};

AnimeView.defaultProps = {
  anime: null
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimeView);
