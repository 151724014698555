import React from 'react';

const mal = ({id}) => {
  if (id < 1) {
    return null;
  }
  return <a href={`https://myanimelist.net/anime/${id}`} rel="noopener noreferrer" target="_blank">MAL</a>;
};

export default mal;
