import React from 'react';
import { Col, Row, Button } from 'reactstrap';
import axios from '../../../axios-public';
import { time } from '../../../shared/utility';
import AnimeRecommendationItem from '../AnimeRecommandations/AnimeRecommendationItem';

class AnimeRecommandations extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      list: null,
      page: 1,
      pages: 1,
      limit: 6,
      total: 0
    };
    this.buttonHandler = this.buttonHandler.bind(this);
  }

  componentDidMount () {
    this.mounted = true;
    this.fetchData();
    this.interval = setInterval(() => this.fetchData(), 10 * 1000); // every 10 min
  }

  shouldComponentUpdate (nextProps, nextState) {
    return this.mounted;
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  buttonHandler () {
    const copy = { ...this.state };
    let nextPage = 1;
    if (copy.page + 1 < this.state.pages) {
      nextPage = copy.page + 1;
    }
    this.setState({ page: nextPage });
  }

  fetchData () {
    if (typeof localStorage !== 'undefined') {
      const localJson = JSON.parse(localStorage.getItem('widget_anime_recom_slider'));

      if (localJson && localJson.data) {
        this.setState(localJson.data);
        if (localJson.time > time().getTime()) {
          return;
        }
      }
    }

    const windowWidth = window.innerWidth;
    let limit = this.state.limit;
    if (windowWidth > 770 && windowWidth < 993) {
      limit = 6;
    } else if (windowWidth > 577 && windowWidth <= 769) {
      limit = 4;
    } else if (windowWidth <= 577) {
      limit = 2;
    }

    axios.get('anime/recommendations/random?limit=36')
      .then((res) => {
        const pages = Math.floor(res.data.data.length / limit);

        const cacheTime = time();
        cacheTime.setMinutes(cacheTime.getMinutes() + 15);

        const data = {
          time: cacheTime.getTime(),
          data: {
            list: res.data.data,
            pages,
            limit
          }
        };
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('widget_anime_recom_slider', JSON.stringify(data));
        }

        if (this.mounted) {
          this.setState({ list: res.data.data, pages, limit });
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  render () {
    const content = [];
    if (this.state.list && this.mounted) {
      let i = (this.state.limit * (this.state.page - 1));

      for (const index in this.state.list) {
        if (index >= i && i < (this.state.limit * this.state.page)) {
          const obj = this.state.list[index];
          content.push(<AnimeRecommendationItem key={index} user={obj.user} recommendation={obj} />);
          i += 1;
        }
      }
    }
    return (
      <Row className="home-anime-recommendation">
        <Col sm={12}>
          <h5 className="headline p3 clearfix font-size-16">
            Anime Rekomendacijos
            <Button size="xs" className="float-right" onClick={this.buttonHandler}>Daugiau</Button>
          </h5>
        </Col>

        {content}

      </Row>
    );
  }
}

export default AnimeRecommandations;
