import * as actionTypes from './actionTypes';
import axios from '../../axios-public';

export const ajaxLoading = error => ({
  type: actionTypes.ARTICLES_AJAX_LOADING
});

export const ajaxFail = error => ({
  type: actionTypes.ARTICLES_AJAX_FAIL,
  error
});

export const fetchArticlesSuccess = data => ({
  type: actionTypes.ARTICLES_FETCH_LIST_SUCCESS,
  list: data
});

export const fetchArticleSuccess = data => ({
  type: actionTypes.ARTICLES_FETCH_SINGLE_SUCCESS,
  article: data
});

export const fetchArticlesList = () => {
  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get('articles')
      .then((response) => {
        dispatch(fetchArticlesSuccess(response.data.data));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(ajaxFail(err.response.data.message));
        }
        dispatch(ajaxFail(err));
      });
  };
};

export const fetchArticle = (articles, slug) => {
  let article = null;
  if (articles) {
    article = articles.find(obj => obj.slugifyUrl === slug);
  }
  if (article) {
    return (dispatch) => {
      dispatch(fetchArticleSuccess(article));
    };
  }

  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get(`articles/${slug}`)
      .then((res) => {
        dispatch(fetchArticleSuccess(res.data.data));
      })
      .catch((err) => {
        dispatch(ajaxFail(err.response));
      });
  };
};
