import React from 'react';
import { Col, Row } from 'reactstrap';
import ReactGA from 'react-ga';

const donation = (props) => {
  ReactGA.event({
    category: 'PageView',
    action: 'View donation',
    nonInteraction: true
  });

  return (
    <Row>
      <Col sm={12}>
        <h2 className="headline p5">Parama</h2>
      </Col>
      <Col sm={12}>
        <h2 style={{ color: '#4ecca3' }}>Darydami paramą, parašykit savo paskyros vardą</h2>
        <h2>Privalumai:</h2>

        <ul>
          <li>Paremsite puslapį ir vertėjus.</li>
          <li>Gausite papildomu privilegijų puslapyje.</li>
          <li>Greičiau rinksite patirties taškus.</li>
        </ul>
        <h3>Bankinis pavedimas</h3>

        {'Norėdami paremti per bankinį pervedimą, reikia pervesti čia:  '}
        <strong className="highlight-text">Dainius Gliebus LT402140030003811027</strong>
        <br />

        <h4>Paypal</h4>

        {'Norėdami paremti per paypal atlikite pervedimą į '}
        <strong className="highlight-text">dainius.gliebus@gmail.com</strong>
        <br />

        <h4>Paysera</h4>
        <p>Paysera palaiko daugybę įvairių mokėjimų būdų:</p>
        <br />
        <a href="https://www.paysera.com/pay/?data=cHJvamVjdGlkPTI3NzkwJm9yZGVyaWQ9MCZhbW91bnQ9JmN1cnJlbmN5PUVVUiZhY2NlcHR1cmw9aHR0cCUzQSUyRiUyRnd3dy5hbWYubHQmY2FuY2VsdXJsPWh0dHAlM0ElMkYlMkZ3d3cuYW1mLmx0JmNhbGxiYWNrdXJsPSZ0ZXN0PTAmdmVyc2lvbj0xLjY=&sign=b30a79db324543a600ded4f159ce4c3d">
          <img src="/images/Paysera.png" alt="paysera" style={{ maxWidth: '250px' }} />
          <br />
          <br />
          <img src="/images/paysera_methods.png" alt="paysera methods" style={{ maxWidth: '500px', width: '100%' }} />
        </a>

      </Col>
    </Row>
  );
};

export default donation;
