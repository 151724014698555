import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AnimeRecommandations from '../../components/Anime/AnimeRecommandations/AnimeRecommandations';
import AnimeNewReleaseSlider from '../../components/Anime/NewReleaseSlider/NewReleaseSlider';
import AnimeNewSeasonReleaseSlider from '../../components/Anime/NewReleaseSlider/AnimeNewSeasonReleaseSlider';
import Helmet from '../../components/Helmet/Helmet';
import Aux from '../../hoc/Aux/Aux';
import AnimeSlider from '../../components/Widgets/AnimeSlider/AnimeSlider';

const homepage = (props) => {
  const extra = [];
  if (props.isAuth) {
    if (props.displayWatching) {
      extra.push(<AnimeSlider key={0} title="Mano paskutiniai žiūrėti anime" endpoint="widgets/anime-last-watched" online />);
    }

    if (props.displayWishlist) {
      extra.push(<AnimeSlider key={1} title="Planuojami žiūrėti anime" endpoint="widgets/anime-wishlist" />);
    }

    if (props.displayGenres) {
      extra.push(<AnimeSlider key={2} title="Anime pagal pasirinktus žanrus" endpoint="widgets/anime-watching-genres" />);
    }
  }

  return (
    <Aux>
      <Helmet
        title="AMF - anime lietuviškai, nemokamai ir visada geriausi vertimai"
        description="AnimeOnline.lt - tai ilgiausiai gyvuojantis vertėjų puslapis, turintis daugiausiai ir populiariausių anime vertimų bazę. Nuo Lietuviškai subtitruotų iki įgarsintų"
      >
        <meta name="robots" content="INDEX, FOLLOW" />
      </Helmet>
      <AnimeNewSeasonReleaseSlider />
      <AnimeNewReleaseSlider />
      {extra}
      <AnimeRecommandations />
    </Aux>
  );
};

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null,
  displayWishlist: state.auth.displayWishlist,
  displayWatching: state.auth.displayWatching,
  displayGenres: state.auth.displayGenres
});

homepage.propTypes = {
  isAuth: PropTypes.bool,
  displayWishlist: PropTypes.bool,
  displayWatching: PropTypes.bool,
  displayGenres: PropTypes.bool
};

homepage.defaultProps = {
  isAuth: false,
  displayWishlist: false,
  displayWatching: false,
  displayGenres: false
};

export default connect(mapStateToProps)(homepage);
