import React from 'react';

export const JsonLd = ({ data }) => (
  <script type="application/ld+json">
    {JSON.stringify(data)}
  </script>
);

export const JsonBreadcumbLd = ({ items }) => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: 'Titulinis',
          item: 'https://animeoneline.lt'
        },
        [...items]
      ]
    })}
  </script>
);
