import React, { Component } from 'react';
import {
  Collapse, Button, CardBody, Card
} from 'reactstrap';
import PropTypes from 'prop-types';

class Spoiler extends Component {
  constructor (props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false };
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextState.collapse !== this.state.collapse) {
      return true;
    }
    return false;
  }

  toggle () {
    this.setState({ collapse: !this.state.collapse });
  }

  render () {
    return (
      <span>
        <Button color="warning" outline onClick={this.toggle} style={{ marginBottom: '1rem' }} size="xs">Spoileris</Button>
        <Collapse isOpen={this.state.collapse}>
          <Card>
            <CardBody style={{ color: '#000' }}>
              {this.props.children}
            </CardBody>
          </Card>
        </Collapse>
      </span>
    );
  }
}

Spoiler.propTypes = {
  children: PropTypes.any.isRequired
};

export default Spoiler;
