import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image';
import axios from '../../../axios-public';
import Aux from '../../../hoc/Aux/Aux';

class Slider extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      anime: [],
      page: 1,
      pages: 1,
      limit: 5,
      total: 0,
      thumb: true
    };
    this.buttonHandler = this.buttonHandler.bind(this);
  }

  componentDidMount () {
    this.fetchNewRelease();
  }

  buttonHandler () {
    const copy = { ...this.state };
    let nextPage = 1;
    if (copy.page + 1 < this.state.pages) {
      nextPage = copy.page + 1;
    }
    this.setState({ page: nextPage });
  }

  fetchNewRelease () {
    const windowWidth = window.innerWidth;
    const thumb = windowWidth < 1400;
    let limit = this.state.limit;
    if (windowWidth > 770 && windowWidth < 993) {
      limit = 4;
    } else if (windowWidth <= 577) {
      limit = 2;
    }

    axios.get(this.props.endpoint)
      .then((res) => {
        const pages = Math.floor(res.data.data.length / limit);
        this.setState({
          anime: res.data.data, total: res.data.data.length, pages, limit, loading: false, thumb
        });
      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  render () {
    const content = [];
    if (this.state.anime && !this.state.loading) {
      let i = (this.state.limit * (this.state.page - 1));

      for (const index in this.state.anime) {
        if (index >= i && i < (this.state.limit * this.state.page)) {
          let subTitle = null;
          const anime = this.state.anime[index];
          let oriPicture = anime.picture;

          if (!this.state.thumb) {
            oriPicture = oriPicture.replace('thumb/', '');
          }

          const picture = oriPicture.replace('anime/', 'anime/thumb/');

          let url = `/anime/${anime.slugifyUrl}`;
          if (this.props.online) {
            const type = anime.type === 'subtitruota' ? 'subs' : 'audio';

            url = `/online/${anime.slugifyUrl}/ep${anime.ep}-${type}`;
            subTitle = `${anime.ep} dalis, lt ${anime.type}`;
          }

          content.push(
            <Col key={index} className="release-box">
              <Link to={url}>
                <div className="ncontainer">
                  <Image
                    className="image"
                    src={picture}
                    srcSet={[picture.replace('.webp', '.jpg')]}
                    alt={anime.name.replace(/[^0-9a-zA-Z ,]/g, '')}
                  />
                  <div className="middle">
                    <div className="text">
                      <h5>{anime.name}</h5>
                      {subTitle}
                      <br />
                      <br />
                      {anime.genres}
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          );
          i += 1;
        }
      }
    }

    let more = null;
    if (this.state.pages > 1) {
      more = <Button size="xs" className="float-right" onClick={this.buttonHandler}>Daugiau</Button>;
    }
    return (
      <Aux>
        <Row>
          <Col sm={12}>
            <h5 className="headline p3 clearfix">
              {this.props.title}
              {more}
            </h5>
          </Col>
        </Row>
        <Row className="newReleaseSlider" style={{ paddingLeft: '15px' }}>
          {content}
        </Row>
      </Aux>
    );
  }
}

Slider.propTypes = {
  title: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  online: PropTypes.bool
};

Slider.defaultProps = {
  online: false
};

export default Slider;
