import React from 'react';
import { Route } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import Aux from '../Aux/Aux';

const EmptyLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <Aux>
        <NavBar />
        <Component {...matchProps} />
      </Aux>
    )}
  />
);

export default EmptyLayout;
