import React from 'react';
import { Row, Container } from 'reactstrap';
import './Footer.css';

const footer = () => (
  <Row className="footer">
    <Container>
      <footer className="text-center">
        <p className="community">Lietuvos anime bendruomenė</p>
        <p>&copy; 2009-2019 AnimeOnline.LT All Rights Reserved. Email: admin@animeminusfam.lt</p>
      </footer>
    </Container>
  </Row>
);

export default footer;
