import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import Translate from 'translate-components';
import styles from './Item.module.css';

const item = ({ anime, onlyTwo }) => {
  const genres = anime.genres.map((genre, index) => (
    <span key={index}>
      <Badge><Translate>{genre.genre}</Translate></Badge>
      {' '}
    </span>
  ));

  const picture = anime.image_url;

  const title = [anime.name];
  if (anime.alternativeName && anime.name.toLowerCase() !== anime.alternativeName.toLowerCase()) {
    title.push(<br key={2} />);
    title.push(`(${anime.alternativeName})`);
  }

  const sizeSm = 6;
  let sizeLg = 4;
  if (onlyTwo) {
    sizeLg = 6;
  }

  return (
    <Col sm={sizeSm} lg={sizeLg} className={styles.searchBox} key={anime.id}>
      <Row>
        <Col sm={12}>
          <Link to={anime.url}>
            <h4 className={styles.title}>{title}</h4>
          </Link>
        </Col>
        <Col sm={12} className="text-center">
          {`${anime.type} ${anime.series} dalis`}
        </Col>
        <Col sm={12} className={styles.genres}>
          {genres}
        </Col>
        <Col sm={12} className={styles.scrollable}>
          <Link to={anime.url}>
            <img alt={anime.name} src={picture} srcSet={picture.replace('.webp', '.jpg')} className={styles.image} />
          </Link>
          <span className="content">
            {anime.description.replace(/\[(\w+)[^\]]*](.*?)\[\/\1]/g, '').replace(/<iframe.+?<\/iframe>/g, '')}
          </span>
        </Col>
        <Col xs={5} className={styles.year}>
          {`Metai ${anime.year}`}
        </Col>
        <Col xs={3} className={styles.score}>
          <span className="glyphicon glyphicon-star" aria-hidden="true" />
          {' '}
          {anime.malScore}
        </Col>
        <Col xs={4} className={styles.views}>
          <span className="glyphicon glyphicon-user" aria-hidden="true" />
          {' '}
          {anime.views}
        </Col>
      </Row>
    </Col>
  );
};

item.propTypes = {
  anime: PropTypes.any.isRequired,
  onlyTwo: PropTypes.bool
};

item.defaultProps = {
  onlyTwo: false
};

export default item;
