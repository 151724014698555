import React from 'react';

const anidb = ({id}) => {
  if (id < 1) {
    return null;
  }
  return <a href={`https://anidb.net/perl-bin/animedb.pl?show=anime&aid=${id}`} rel="noopener noreferrer" target="_blank">AniDB</a>;
};

export default anidb;
