import jwtDecode from 'jwt-decode';
import * as actionTypes from './actionTypes';
import axios from '../../axios-public-new';
import { getCookie, setCookie, time } from '../../shared/utility';

const saveToken = (token) => {
  const expirationDate = new Date(jwtDecode(token).exp * 1000); // bloody miliseconds
  const expiresIn = Math.round((expirationDate.getTime() - time().getTime()) / 1000);

  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate);
  }
  setCookie('token', token, 720);
  setCookie('expirationDate', expirationDate, 720);

  return expiresIn;
};

const cleanStorage = () => {
  if (typeof localStorage !== 'undefined') {
    const validKeys = [
      'articles', '__zamd', 'cmsList', 'donation', 'expirationDate', 'token', 'animeList', 'mangaList', 'guest',
      'widget_anime_recom_slider', 'widget_anime_slider', 'widget_online_list', 'widget_random_anime', 'widget_poll'

    ];

    const keys = Object.keys(localStorage);

    for (let i = 0; i < keys.length; i += 1) {
      if (validKeys.indexOf(keys[i]) < 0) {
        localStorage.removeItem(keys[i]);
      }
    }
  }
};

const notLoggedInCookies = () => {
  const newGuest = `guest_${time().getTime()}`;

  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    const guest = localStorage.getItem('guest');
    if (guest === null || guest === '') {
      localStorage.setItem('guest', newGuest);
    }
  }
  setCookie('token', null, 0);
  setCookie('expirationDate', null, 0);
  const cguest = getCookie('guest');
  if (!cguest) {
    setCookie('guest', newGuest, 720);
  }
};

export const authStart = () => ({
  type: actionTypes.AUTH_START
});

export const ajaxLoading = () => ({
  type: actionTypes.AUTH_AJAX_START
});

export const authSuccess = token => (
  {
    type: actionTypes.AUTH_SUCCESS,
    token,
    userId: jwtDecode(token).sub,
    role: jwtDecode(token).role,
    design: jwtDecode(token).design,
    username: jwtDecode(token).username,
    username_canonical: jwtDecode(token).username_canonical,
    displayArticles: jwtDecode(token).display_articles || false,
    displayWishlist: jwtDecode(token).display_wishlist || false,
    displayWatching: jwtDecode(token).display_watching || false,
    displayGenres: jwtDecode(token).display_genres || false
  }
);

export const authUserData = data => (
  {
    type: actionTypes.AUTH_SUCCESS,
    token: data.jwt,
    userId: jwtDecode(data.jwt).sub,
    role: jwtDecode(data.jwt).role,
    design: jwtDecode(data.jwt).design,
    username: jwtDecode(data.jwt).username,
    username_canonical: jwtDecode(data.jwt).username_canonical,
    displayArticles: jwtDecode(data.jwt).display_articles || false,
    displayWishlist: jwtDecode(data.jwt).display_wishlist || false,
    displayWatching: jwtDecode(data.jwt).display_watching || false,
    displayGenres: jwtDecode(data.jwt).display_genres || false,
    member: data.member || 'Narys',
    member_until: data.member_until || '2022-06-01'
  }
);

export const passwordUpdated = token => ({
  type: actionTypes.AUTH_PASSWORD_UPDATED,
  token,
  userId: jwtDecode(token).sub,
  role: jwtDecode(token).role,
  design: jwtDecode(token).design,
  username: jwtDecode(token).username
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error
});

export const ajaxFail = error => ({
  type: actionTypes.AUTH_AJAX_FAIL,
  error
});

export const recoveryEmailSent = email => ({
  type: actionTypes.AUTH_PASSWORD_RECOVERY_SENT,
  email
});

export const seenNofitcationMarked = id => ({
  type: actionTypes.AUTH_USER_SEEN_MARK,
  seen: id
});

export const userNotifications = notifications => ({
  type: actionTypes.AUTH_USER_NOTIFICATIONS,
  notifications
});

export const userLevel = level => ({
  type: actionTypes.AUTH_USER_LEVEL,
  level
});

export const auth = (email, password) => (dispatch) => {
  dispatch(authStart());

  axios.post('auth/login', {
    email,
    password
  })
    .then((res) => {
      const token = res.data.data2 && res.data.data2.jwt ? res.data.data2.jwt : res.data.data;

      const expiresIn = saveToken(token);

      if (res.data.data2) {
        setCookie('member', res.data.data2.member, 720);
        setCookie('member_until', res.data.data2.member_until, 720);
        dispatch(authUserData(res.data.data2));
      } else {
        dispatch(authSuccess(token));
      }
      dispatch(checkOffTimeout(expiresIn));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(authFail(err.response.data.message));
      } else {
        dispatch(authFail('Connection Error'));
      }
    });
};

export const logout = () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
  }
  setCookie('token', null, 0);
  setCookie('expirationDate', null, 0);
  setCookie('member', null, 0);
  setCookie('member_until', null, 0);
  const cguest = getCookie('guest');
  if (!cguest) {
    setCookie('guest', `guest_${time().getTime()}`, 720);
  }
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkOffTimeout = expirationTime => (dispatch) => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime * 1000);
};

export const setAuthRedirectPath = path => ({
  type: actionTypes.SET_AUTH_REDIRECT_PATH,
  path
});

export const authCheckState = cookies => (dispatch) => {
  cleanStorage();
  const timestamp = time().getTime();

  if (typeof localStorage !== 'undefined') {
    const token = localStorage.getItem('token');
    if (token) {
      const decoded = jwtDecode(token);
      if (timestamp < decoded.exp * 1000) {
        const newData = {
          jwt: token,
          member: getCookie('member'),
          member_until: getCookie('member_until')
        };
        dispatch(authUserData(newData));

        const expirationDate = new Date(decoded.exp * 1000); // bloody miliseconds
        const expiresIn = Math.round((expirationDate.getTime() - timestamp) / 1000);
        dispatch(checkOffTimeout(expiresIn));
      } else {
        notLoggedInCookies();
      }
    } else {
      notLoggedInCookies();
    }
  }
};

export const authPasswordUpdate = (oldPassword, newPassword) => (dispatch) => {
  axios.post('auth/password-change', { oldPassword, newPassword })
    .then((res) => {
      if (res.data.message === 'success') {
        const token = res.data.data;
        const expiresIn = saveToken(token);
        dispatch(authSuccess(token));
        dispatch(checkOffTimeout(expiresIn));
      } else {
        dispatch(ajaxFail(res.data.data));
      }
    })
    .catch((err) => {
      if (err.res) {
        dispatch(authFail(err.res.data.message));
      } else {
        dispatch(authFail('Connection Error'));
      }
    });
};

export const passwordRecovery = (resetToken, password) => (dispatch) => {
  dispatch(ajaxLoading());
  axios.post('auth/password-reset', { resetToken, password })
    .then((res) => {
      const token = res.data.data;
      const expiresIn = saveToken(token);
      dispatch(authSuccess(token));
      dispatch(checkOffTimeout(expiresIn));
    })
    .catch((err) => {
      dispatch(ajaxFail(err.res.data.message));
    });
};

export const activateUser = (email, acitvationToken) => {
  const data = {
    email,
    token: acitvationToken
  };
  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.post('auth/activate', data)
      .then((res) => {
        const token = res.data.data;
        const expiresIn = saveToken(token);
        dispatch(authSuccess(token));
        dispatch(checkOffTimeout(expiresIn));
      })
      .catch((err) => {
        if (err.res) {
          dispatch(ajaxFail(err.res.data.data));
        } else {
          dispatch(ajaxFail(err));
        }
      });
  };
};

export const unseenNofitcations = () => (dispatch) => {
  axios.get('users/notifications')
    .then((res) => {
      dispatch(userNotifications(res.data.data));
    })
    .catch((err) => {
      if (err.res && err.res.data) {
        dispatch(ajaxFail(err.res.data.message));
      } else {
        dispatch(ajaxFail('Undefined error'));
      }
    });
    axios.get('users/level')
    .then((res) => {
      dispatch(userLevel(res.data.data));
    })
    .catch((err) => {
      if (err.res && err.res.data) {
        dispatch(ajaxFail(err.res.data.message));
      } else {
        dispatch(ajaxFail('Undefined error'));
      }
    });
};

export const seenNofitcation = id => (dispatch) => {
  axios.post(`users/notifications/${id}`)
    .then((res) => {
      dispatch(seenNofitcationMarked(id));
    })
    .catch((err) => {
      if (err.res && err.res.data) {
        dispatch(ajaxFail(err.res.data.message));
      } else {
        dispatch(ajaxFail('Undefined error'));
      }
    });
};

export const authSocialLogin = (social, response) => (dispatch) => {
  dispatch(authStart());

  axios.post(`auth/${social}`, response)
    .then((res) => {
      const token = res.data.data;
      const expiresIn = saveToken(token);
      dispatch(authSuccess(token));
      dispatch(checkOffTimeout(expiresIn));
    })
    .catch((err) => {
      if (err.res) {
        dispatch(authFail(err.res.data.message));
      } else {
        dispatch(authFail('Connection Error'));
      }
    });
};

export const setAuthUserMal = list => (dispatch) => {
  dispatch({
    type: actionTypes.AUTH_USER_MAL,
    list
  });
};
