import React, { Component } from 'react';
import Aux from '../../../hoc/Aux/Aux';
import axios from '../../../axios-public';
import RatingStars from '../../UI/RatingStars/RatingStars';

class MalScore extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: false,
      score: 0,
      following: 0,
      watched: 0
    };
  }

  componentDidMount () {
    if (this.props.anime) {
      this.fetchData();
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.anime !== prevProps.anime) {
      this.fetchData();
    }
  }

  fetchData () {
    axios.get(`anime/${this.props.anime.id}/mal`)
      .then((res) => {
        this.setState({
          watched: (res.data.data.Watching + res.data.data.Finished),
          score: res.data.data.score,
          following: (res.data.data.Watching + res.data.data.Stopped + res.data.data.Finished + res.data.data.Plan)
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render () {
    return (
      <Aux>
        <strong>Balas:</strong>
        {' '}
        <span className="highlight-text"><RatingStars score={this.props.malScore} /></span>
        <br />
        <strong>Stebi:</strong>
        {' '}
        <span className="highlight-text">{this.state.following}</span>
        {' '}
        <strong>Žiūrėjo:</strong>
        {' '}
        <span className="highlight-text">{this.state.watched}</span>
      </Aux>
    );
  }
}

export default MalScore;
