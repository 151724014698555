// import 'react-app-polyfill/stable';
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// import 'bootstrap/dist/css/bootstrap.min.css'
import { TranslateProvider } from 'translate-components';
import { CookiesProvider } from 'react-cookie';
import HttpsRedirect from 'react-https-redirect';
import translations from './translations/translations.json';

import App from './App';
import * as serviceWorker from './serviceWorker';
import articlesReducer from './store/reducers/articlesReducer';
import authReducer from './store/reducers/authReducer';
import animeReducer from './store/reducers/animeReducer';
import cmsReducer from './store/reducers/cmsReducer';
import mangaReducer from './store/reducers/mangaReducer';
import shoutboxReducer from './store/reducers/shoutbox';

const rootReducer = combineReducers({
  articles: articlesReducer,
  auth: authReducer,
  anime: animeReducer,
  manga: mangaReducer,
  cms: cmsReducer,
  shoutbox: shoutboxReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const app = (
  <Provider store={store}>
    <HttpsRedirect>
      <CookiesProvider>
        <BrowserRouter>
          <TranslateProvider translations={translations} defaultLanguage="lt">
            <App />
          </TranslateProvider>
        </BrowserRouter>
      </CookiesProvider>
    </HttpsRedirect>
  </Provider>
);

ReactDOM.render(app, document.getElementById('app'));
// ReactDOM.hydrate(app, document.getElementById('app'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
