import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Col, Row, BreadcrumbItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import {
  FacebookProvider, Share
} from 'react-facebook';
import Spinner from '../../components/UI/Spinner/Spinner';
import Helmet from '../../components/Helmet/Helmet';
import Aux from '../../hoc/Aux/Aux';
import axios from '../../axios-public';
import OnlineThanks from '../../components/Online/OnlineThanks/OnlineThanks';
import * as actions from '../../store/actions';
import { JsonBreadcumbLd } from '../../shared/JsonLd';

import asyncComponent from '../../components/AsyncComponent';

const Comments = asyncComponent(() => import('../../components/Comments/Comments'));
const VideoPlayer = asyncComponent(() => import('../../components/Player/VideoPlayer'));
const ReviewModal = asyncComponent(() => import('../../components/Online/ReviewModal/ReviewModal'));
const OnlinePageLogin = asyncComponent(() => import('../../components/Online/OnlinePageLogin/OnlinePageLogin'));

class OnlineView extends Component {
  constructor (props) {
    super(props);
    this.state = {
      type: 'all',
      reviewFormDisplay: false,
      online: null,
      error: null,
      loading: true
    };

    this.showReviewForm = this.showReviewForm.bind(this);
    this.toggleReviewForm = this.toggleReviewForm.bind(this);
    this.handleAutoplayNextEpisode = this.handleAutoplayNextEpisode.bind(this);
    this.fbRef = React.createRef();
  }

  componentDidMount () {
    this.fetchOnline();
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.match.params.slug !== prevProps.match.params.slug ||
            this.props.match.params.ep !== prevProps.match.params.ep ||
            this.props.isAuth !== prevProps.isAuth ||
            this.props.match.params.type !== prevProps.match.params.type
    ) {
      this.fetchOnline();
    }
  }

  componentWillUnmount () {
    const wallpaper = document.getElementById('wallpaper');
    if (wallpaper) {
      wallpaper.style.background = 'none';
      wallpaper.style.size = 'cover';
    }
  }

  fetchOnline () {
    const slug = this.props.match.params.slug;
    const ep = this.props.match.params.ep;
    const type = this.props.match.params.type;
    this.setState({ loading: true, error: null });
    axios.get(`online/slug?slug=${slug}&ep=${ep}&type=${type}`)
      .then((response) => {
        this.setState({ online: response.data.data, loading: false });
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          this.props.onUnAutorize();
          this.setState({ loading: false });
        } else {
          this.setState({ loading: false, error: 'Krovimo klaida! Perkraukite puslapį!' });
        }
      });
  }

  showReviewForm (e) {
    this.setState({ reviewFormDisplay: true });
    ReactGA.event({
      category: 'Social',
      action: 'Show review form',
      nonInteraction: true
    });
  }

  toggleReviewForm (e) {
    this.setState({ reviewFormDisplay: !this.state.reviewFormDisplay });
  }

  handleAutoplayNextEpisode(nextEpisode) {
    if(nextEpisode.url) {
      this.props.history.push(`${nextEpisode.url}?play=true`);
    }
  }

  render () {
    const params = new URLSearchParams(this.props.location.search);
    const today = new Date();

    let title = 'Anime Online';
    let publikuota = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
    let translators = null;
    let player = <Spinner />;
    let thanks = null;
    let comments = null;
    let prevBtn = null;
    let nextBtn = null;
    let helmet = null;
    let breadcrumbAnime = null;
    let breadcrumbOnlineEp = null;
    let reviewModal = null;
    let studioBread = null;
    const breadcrumbs = [
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Online',
        item: 'https://animeoneline.lt/online'
      }
    ];

    if (!this.state.loading && this.state.error) {
      player = <div>Krovimo klaida</div>;
    } else if (!this.state.loading && this.state.online && this.state.online.anime) {
      const online = this.state.online;
      let subTitle = this.state.online.anime.name;
      if (this.state.online.anime.alternativeName) {
        subTitle += ` (${this.state.online.anime.alternativeName}) `;
      }

      const metaTitle = `${subTitle} - ${online.ep} dalis anime lietuviškai nemokamai`;

      if (this.state.reviewFormDisplay) {
        reviewModal = <ReviewModal toggle={this.toggleReviewForm} display={this.state.reviewFormDisplay} online={this.state.online} />;
      }

      helmet = (
        <Helmet
          title={metaTitle}
          description={`${subTitle} - ${online.ep} dalis lietuviškai nemokamai online, trumpai: ${online.anime.description}`}
        >
          <meta property="og:type" content="video" />
          <meta property="og:image" content={online.anime.image_url} />
          <meta property="og:url" content={`https://animeonline.lt${this.props.location.pathname}`} />
          <meta property="video:tag" content={this.state.online.anime.name} />
          { this.state.online.anime.alternativeName ? <meta property="video:tag" content={this.state.online.anime.alternativeName} /> : null }
          <meta property="video:tag" content={`${online.ep} dalis `} />
          <meta property="video:tag" content={`${online.ep} ep `} />
          <meta property="video:tag" content={this.state.online.typeTxt} />
          <meta property="video:tag" content="lietuviškai nemokamai" />
          <meta name="robots" content="INDEX, FOLLOW" />
          <link rel="canonical" href={`https://animeonline.lt${this.props.location.pathname}`} />
        </Helmet>
      );

      if (this.state.online.anime.series !== 1) {
        title = (
          <Aux>
            <Link to={this.state.online.anime.url}>
              {`${subTitle} ${this.state.online.ep} dalis`}
            </Link>
          </Aux>
        );
      } else {
        title = <Link to={this.state.online.anime.url}>{subTitle}</Link>;
      }

      publikuota = this.state.online.created_at.slice(0, 10);

      if (this.state.online.previous) {
        prevBtn = (
          <div className="float-left">
            <Link to={this.state.online.previous.url}>
              {'<< '}
              {this.state.online.previous.title}
            </Link>

          </div>
        );
      }
      if (this.state.online.next) {
        nextBtn = (
          <div className="float-right">
            <Link to={this.state.online.next.url}>
              {this.state.online.next.title}
              {' >>'}
            </Link>

          </div>
        );
      }

      if (this.props.isAuth || this.state.online.free) {
        if (this.state.online.placeholder) {
          player = (
            <h3 style={{ textAlign: 'center', paddingTop: '150px', paddingBottom: '150px' }}>
              <br />
              =======================
              <br />
              Rodomas: {this.state.online.display_from_time}
              <br />
              =======================
              <br />
            </h3>
          );
        } else if (this.state.online.source !== '') {
          player = (
            <VideoPlayer
              triggerRecommend={this.state.online.triggerRecommend}
              lastEpisode
              server={this.state.online.server}
              showReviewForm={this.showReviewForm}
              online_id={this.state.online.id}
              anime_id={this.state.online.anime.id}
              source={`https://s1.amf.lt/${this.state.online.source}`}
              type={this.state.online.format}
              nextEpisode={this.state.online.next}
              videoType={this.props.match.params.type}
              onNextEpisode={this.handleAutoplayNextEpisode}
              autoPlay={params.get('play') === 'true'}
            />
          );
        } else {
          player = (
            <h3 style={{ textAlign: 'center', paddingTop: '150px', paddingBottom: '150px' }}>
              Rodomas: {this.state.online.display_from_time}
              <br />
              =======================
              <br />
              Nori žiūrėti iškart? Palaikyk puslapį – <Link to="/parama">tapk rėmėju.</Link>
            </h3>
          );
        }
      } else {
        player = <OnlinePageLogin />;
      }

      thanks = <OnlineThanks id={this.state.online.id} initial={this.state.online.thanks} />;

      translators = this.state.online.translators.map(translator => (
        <Aux key={translator.username}>
          <Link to={translator.url}>{translator.username}</Link>
          {` (${translator.position}) `}
        </Aux>
      ));

      const onlineComments = this.state.online.comments.data ? this.state.online.comments.data : [];
      comments = <Comments id={this.state.online.id} type="online" initial={onlineComments} />;

      breadcrumbAnime = (
        <BreadcrumbItem>
          <Link to={`/anime/${this.state.online.anime.slug}`}>{this.state.online.anime.name}</Link>
        </BreadcrumbItem>
      );
      breadcrumbOnlineEp = (
        <BreadcrumbItem active>
          <Link to={this.props.location.pathname}>
            {`${this.state.online.ep} dalis ${this.state.online.typeTxt}`}
          </Link>
        </BreadcrumbItem>
      );
      let breadcrumbsCount = 3;

      if (this.state.online.anime.studios && this.state.online.anime.studios.length > 0) {
        studioBread = (
          <BreadcrumbItem>
            <Link to={`/anime/studija/${this.state.online.anime.studios[0].slugifyUrl}`}>
              {this.state.online.anime.studios[0].title}
            </Link>
          </BreadcrumbItem>
        );
        breadcrumbs.push({
          '@type': 'ListItem',
          position: breadcrumbsCount,
          name: this.state.online.anime.studios[0].title,
          item: `https://animeoneline.lt/anime/studija/${this.state.online.anime.studios[0].slugifyUrl}`
        });
        breadcrumbsCount += 1;
      }

      breadcrumbs.push({
        '@type': 'ListItem',
        position: breadcrumbsCount,
        name: this.state.online.anime.name,
        item: `https://animeoneline.lt/anime/${this.state.online.anime.slug}`
      });
      breadcrumbsCount += 1;
      breadcrumbs.push({
        '@type': 'ListItem',
        position: breadcrumbsCount,
        name: `${this.state.online.ep} dalis ${this.state.online.typeTxt}`,
        item: `https://animeoneline.lt${this.props.location.pathname}`
      });

      const wallpaper = document.getElementById('wallpaper');
      const windowWidth = window.innerWidth;

      if (wallpaper && this.state.online.anime.wallpaper && windowWidth > 1000) {
        wallpaper.style.background = "url('https://animeonline.lt/images/anime/wallpaper/" + this.state.online.anime.slug + ".jpg') no-repeat center center fixed";
        wallpaper.style.size = 'cover';
      } else if (wallpaper) {
        wallpaper.style.background = 'none';
        wallpaper.style.size = 'cover';
      }
    } else if (!this.state.loading && !this.props.isAuth) {
      player = <OnlinePageLogin />;
    } else if (!this.state.loading) {
      player = (
        <h3 style={{ textAlign: 'center', paddingTop: '150px', paddingBottom: '150px' }}>
          Krovimo klaida!
        </h3>
      );
    }

    return (
      <Aux>
        {helmet}
        <h1 className="headline p3 clearfix">{title}</h1>
        {reviewModal}
        <Row>
          <Col sm={9}>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <BreadcrumbItem>
                  <Link to="/">Titulinis</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/online">Online</Link>
                </BreadcrumbItem>
                {studioBread}
                {breadcrumbAnime}
                {breadcrumbOnlineEp}
              </ol>
            </nav>
          </Col>
          <Col sm={3}>
            <div className="text-right font-size-12">{`Publikuota: ${publikuota}`}</div>
          </Col>
          <Col sm={12} className="font-size-14">
            {translators}
          </Col>
        </Row>
        {player}
        <Row className="mobile-mt-16">
          <Col sm={6}>{prevBtn}</Col>
          <Col sm={6} className="mobile-mt-16">{nextBtn}</Col>

          <Col sm={12} className="font-size-14 desktop-mt-10 mobile-mt-16">
            <FacebookProvider appId="1027970143892691">
              <Share href={window.location.href.replace('amf', 'animeonline')}>
                {({ handleClick, loading }) => (
                  <button type="button" disabled={loading} onClick={handleClick} id="fb-share-button">
                    <svg viewBox="0 0 12 12" preserveAspectRatio="xMidYMid meet">
                      <path className="svg-icon-path" d="M9.1,0.1V2H8C7.6,2,7.3,2.1,7.1,2.3C7,2.4,6.9,2.7,6.9,3v1.4H9L8.8,6.5H6.9V12H4.7V6.5H2.9V4.4h1.8V2.8 c0-0.9,0.3-1.6,0.7-2.1C6,0.2,6.6,0,7.5,0C8.2,0,8.7,0,9.1,0.1z" />
                    </svg>
                    <span>Dalintis</span>
                  </button>
                )}
              </Share>
            </FacebookProvider>
            {' '}
            {thanks}
          </Col>
        </Row>

        {comments}
        <JsonBreadcumbLd items={breadcrumbs} />
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null
});

const mapDispatchToProps = dispatch => ({
  onUnAutorize: () => dispatch(actions.logout())
});

OnlineView.propTypes = {
  location: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  onUnAutorize: PropTypes.func.isRequired,
  isAuth: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineView);
