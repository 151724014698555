import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './components/UI/Spinner/Spinner';

import DefaultLayout from './hoc/Layout/DefaultLayout';
import EmptyLayout from './hoc/Layout/EmptyLayout';
import OnePageLayout from './hoc/Layout/OnePageLayout';

import HomePage from './containers/HomePage/HomePage';
import AnimeView from './containers/Anime/AnimeView';
import AnimeSearch from './containers/Anime/AnimeSearch';
import OnlineView from './containers/Online/OnlineView';
import Logout from './containers/Auth/Logout/Logout';
import CMSPage from './containers/Misc/CMSPage';
import Donation from './containers/Misc/Donation';
import PageNotFound from './containers/Misc/PageNotFound';

const OnlineList = lazy(() => import('./containers/Online/OnlineList'));

const SearchPage = lazy(() => import('./containers/Misc/SearchPage'));
const Article = lazy(() => import('./containers/Articles/Article'));

const AnimeStudio = lazy(() => import('./containers/Anime/AnimeStudio'));
const AnimeRecommendations = lazy(() => import('./containers/Anime/AnimeRecommendations'));
const AnimeList = lazy(() => import('./containers/Anime/AnimeList'));

const UserMAL = lazy(() => import('./containers/Users/UserMAL'));
const UserPasswordChange = lazy(() => import('./containers/Users/UserPasswordChange'));
const UserActivation = lazy(() => import('./containers/Users/UserActivation'));
const UserPasswordReset = lazy(() => import('./containers/Users/UserPasswordReset'));

const ArticlesList = lazy(() => import('./containers/Articles/Articles'));
const OnlineProjects = lazy(() => import('./containers/Online/OnlineProjects'));

const Login = lazy(() => import('./containers/Login/Login'));

const FanZona = lazy(() => import('./containers/Misc/FanZona'));
const Shoutbox = lazy(() => import('./containers/Misc/Shoutbox'));
const JoinTeam = lazy(() => import('./containers/Misc/JoinTeam'));
const UserTop = lazy(() => import('./containers/Misc/UserTop'));
const OnlinePlans = lazy(() => import('./containers/Misc/Plans'));
const GenericTop = lazy(() => import('./containers/Misc/GenericTop'));

const UserView = lazy(() => import('./containers/Users/UserView'));
const UserEdit = lazy(() => import('./containers/Users/UserEdit'));
const UserChats = lazy(() => import('./containers/Users/UserChats'));
const UserComments = lazy(() => import('./containers/Users/UserComments'));
const UserSecurity = lazy(() => import('./containers/Users/UserSecurity'));

const MangaList = lazy(() => import('./containers/Manga/MangaList'));
const MangaView = lazy(() => import('./containers/Manga/MangaView'));
const MangaRead = lazy(() => import('./containers/Manga/MangaRead'));

const browserPages = props => (
  <React.Suspense fallback={<Spinner />}>
    <Switch>
      <Redirect path="/online-:ending" to="/404" />
      <Redirect path="/anime-online-:ending" to="/404" />
      <DefaultLayout path="/" exact component={HomePage} />
      <DefaultLayout path="/anime" exact component={AnimeList} />
      <DefaultLayout path="/anime-recommendations" exact component={AnimeRecommendations} />
      <OnePageLayout path="/anime-paieska" exact component={AnimeSearch} />
      <DefaultLayout path="/anime/studija/:slug" exact component={AnimeStudio} />
      <DefaultLayout path="/anime/:slug" exact component={AnimeView} />
      <DefaultLayout path="/manga" exact component={MangaList} />
      <DefaultLayout path="/manga/:slug/ch:chapter/:page" exact component={MangaRead} />
      <DefaultLayout path="/manga/:slug" exact component={MangaView} />
      <DefaultLayout path="/straipsnis/:article" exact component={Article} />
      <DefaultLayout path="/straipsnis" exact component={ArticlesList} />
      <DefaultLayout path="/fan-zona" exact component={FanZona} />
      <DefaultLayout path="/prisidek" exact component={JoinTeam} />
      <DefaultLayout path="/puslapis/:slug" exact component={CMSPage} />
      <DefaultLayout path="/online" exact component={OnlineList} />
      <DefaultLayout path="/online/:slug/ep:ep-:type" exact component={OnlineView} sidebar="online" />
      <DefaultLayout path="/dabartiniai-projektai" exact component={OnlineProjects} />
      <OnePageLayout path="/profilis/:slug" exact component={UserView} />
      <OnePageLayout path="/anime-sarasas/:slug" exact component={UserMAL} />
      <DefaultLayout path="/paieska" exact component={SearchPage} />
      <DefaultLayout path="/parama" exact component={Donation} />
      <OnePageLayout path="/saukykla" exact component={Shoutbox} />
      <DefaultLayout path="/vartotoju-top" exact component={UserTop} />
      <DefaultLayout path="/planuojami-ikelimai" exact component={OnlinePlans} />
      <DefaultLayout path="/top-:type" exact component={GenericTop} />
      <OnePageLayout path="/profilio/redagavimas" exact component={UserEdit} />
      <OnePageLayout path="/profilio/saugumas" exact component={UserSecurity} />
      <EmptyLayout path="/profilio/slaptazodis" exact component={UserPasswordChange} />
      <OnePageLayout path="/pokalbiai" exact component={UserChats} />
      <OnePageLayout path="/komentarai" exact component={UserComments} />
      <EmptyLayout path="/aktyvavimas" exact component={UserActivation} />
      <EmptyLayout path="/slaptazodzio-keitimas" exact component={UserPasswordReset} />
      <EmptyLayout path="/login" exact component={Login} />
      <Route path="/logout" exact name="Logout" component={Logout} />
      <OnePageLayout path="/404" exact component={PageNotFound} />
      <Redirect path="/" to="/" />
    </Switch>
  </React.Suspense>
);

export default browserPages;
