import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';

const initialState = {
  list: [],
  anime: null,
  error: null,
  loading: false,
  genres: [],
  online: [],
  search: [],
  studios: [],
  current_page: 0,
  last_page: 1
};

const ajaxLoading = (state, action) => updateObject(state, {
  loading: true,
  error: null
});

const ajaxFail = (state, action) => updateObject(state, {
  loading: false,
  error: action.error
});

const fetchList = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  online: null,
  list: action.abc,
  studios: action.studios
});

const fetchListSilent = (state, action) => updateObject(state, {
  list: action.abc,
  studios: action.studios
});

const fetchSingle = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  anime: action.single,
  online: null
});

const fetchSearchResults = (state, action) => updateObject(state, {
  loading: false,
  error: null,
  search: action.list,
  current_page: action.current_page,
  last_page: action.last_page
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ANIME_FETCH_LIST_SUCCESS: return fetchList(state, action);
    case actionTypes.ANIME_FETCH_LIST_SUCCESS_SILENT: return fetchListSilent(state, action);
    case actionTypes.ANIME_FETCH_SINGLE_SUCCESS: return fetchSingle(state, action);
    case actionTypes.ANIME_AJAX_LOADING: return ajaxLoading(state, action);
    case actionTypes.ANIME_AJAX_FAIL: return ajaxFail(state, action);
    case actionTypes.ANIME_FETCH_SEARCH_LIST: return fetchSearchResults(state, action);
    default: return state;
  }
};

export default reducer;
