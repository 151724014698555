import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import axios from '../../../axios-public';
import ThankYouButton from './ThankYouButton';
import Aux from '../../../hoc/Aux/Aux';

class OnlineThanks extends Component {
  constructor (props) {
    super(props);
    this.state = { list: null };
    this.loadThanks = this.loadThanks.bind(this);
  }

  componentDidMount () {
    this.mounted = true;
    if (this.props.initial && this.props.initial.length > 0) {
      this.setState({ list: this.props.initial });
    } else {
      this.loadThanks();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  loadThanks () {
    if (this.props.id > 0) {
      axios.get(`online/${this.props.id}/thanks`)
        .then((response) => {
          if (this.mounted) {
            this.setState({ list: response.data.data });
          }
        });
    }
  }

  render () {
    let thanks = null;
    if (this.state.list) {
      thanks = this.state.list.map((obj, index) => (
        <Link key={index} to={obj.user.profile_url}>{obj.user.username}</Link>
      ));

      if (thanks.length > 0) {
        thanks = thanks.reduce((prev, curr) => [prev, ', ', curr]);
      }
    }

    return (
      <Aux>
        <ThankYouButton online={this.props.id} refresh={this.loadThanks} />
        <div style={{
          borderBottom: '1px solid #000', borderTop: '1px solid #000', marginTop: '10px', marginBottom: '15px', padding: '5px'
        }}
        >
          <strong>Padėkojo:</strong>
          {' '}
          {thanks}
        </div>
      </Aux>
    );
  }
}

OnlineThanks.propTypes = {
  id: PropTypes.number.isRequired
};

export default OnlineThanks;
