import React from 'react';
import {
  Col, Row
} from 'reactstrap';
import Helmet from '../../components/Helmet/Helmet';
import AnimeNewReleaseSlider from '../../components/Anime/NewReleaseSlider/NewReleaseSlider';

const page = props => (
  <Row>
    <Helmet title="Puslapis Nerastas 404" />
    <Col sm={12}>
      <h1 className="headline p3 clearfix">Puslapis nerastas</h1>
      <img src="/images/404.jpg" alt="Prisidėk prie AMF komandos" style={{ textAlign: 'center' }} />
    </Col>
    <Col sm={12}>
      <AnimeNewReleaseSlider />
    </Col>
  </Row>
);

export default page;
