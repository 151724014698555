import * as actionTypes from './actionTypes';
import axios from '../../axios-public';
import { time } from '../../shared/utility';

export const ajaxLoading = () => ({
  type: actionTypes.ANIME_AJAX_LOADING
});

export const ajaxFail = error => ({
  type: actionTypes.ANIME_AJAX_FAIL,
  error
});

export const fetchList = (abc, studios) => ({
  type: actionTypes.ANIME_FETCH_LIST_SUCCESS,
  abc,
  studios
});

export const fetchListSilent = (abc, studios) => ({
  type: actionTypes.ANIME_FETCH_LIST_SUCCESS_SILENT,
  abc,
  studios
});

export const fetchSingle = data => ({
  type: actionTypes.ANIME_FETCH_SINGLE_SUCCESS,
  single: data
});

export const fetchSearchResults = data => ({
  type: actionTypes.ANIME_FETCH_SEARCH_LIST,
  list: data.data,
  current_page: data.current_page,
  last_page: data.last_page
});

const processAnimeList = (data) => {
  const list = {};
  const studios = {};

  for (const index in data) {
    const anime = data[index];
    const check = anime.name.slice(0, 1);

    if (check === '1' || check === '3' || check === '9') {
      if (!list['1']) {
        list['1'] = [];
      }
      list['1'].push(anime);
    } else {
      if (!list[check.toUpperCase()]) {
        list[check.toUpperCase()] = [];
      }
      list[check.toUpperCase()].push(anime);
    }

    for (const studioIndex in anime.studios) {
      const studio = anime.studios[studioIndex];
      if (studio) {
        if (!studios[studio.slugifyUrl]) {
          studios[studio.slugifyUrl] = {
            title: studio.title,
            animes: [anime]
          };
        } else {
          studios[studio.slugifyUrl].animes.push(anime);
        }
      }
    }
  }

  return { abc: list, studios, plain: data };
};

export const fetchAnimeList = () => {
  if (typeof localStorage !== 'undefined') {
    const localJson = JSON.parse(localStorage.getItem('animeList'));

    if (localJson && localJson.time > time().getTime() && localJson.data) {
      return (dispatch) => {
        const processed = processAnimeList(localJson.data);
        dispatch(fetchList(processed.abc, processed.studios));
      };
    }
  }

  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get('anime?limit=2000')
      .then((response) => {
        const cacheTime = time();
        cacheTime.setHours(cacheTime.getHours() + 3);

        const data = {
          time: cacheTime.getTime(),
          data: response.data.data
        };
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('animeList', JSON.stringify(data));
        }

        const processed = processAnimeList(response.data.data);
        dispatch(fetchList(processed.abc, processed.studios));
      })
      .catch((err) => {
        dispatch(ajaxFail(err.response.data.message));
      });
  };
};

export const fetchAnimeListSilent = () => {
  if (typeof localStorage !== 'undefined') {
    const localJson = JSON.parse(localStorage.getItem('animeList'));

    if (localJson && localJson.time > time().getTime() && localJson.data) {
      return (dispatch) => {
        const processed = processAnimeList(localJson.data);
        dispatch(fetchList(processed.abc, processed.studios));
      };
    }
  }

  return (dispatch) => {
    axios.get('anime?limit=2000')
      .then((response) => {
        const cacheTime = time();
        cacheTime.setHours(cacheTime.getHours() + 3);

        const data = {
          time: cacheTime.getTime(),
          data: response.data.data
        };
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem('animeList', JSON.stringify(data));
        }
        const processed = processAnimeList(response.data.data);
        dispatch(fetchListSilent(processed.abc, processed.studios));
      })
      .catch((err) => {
        dispatch(ajaxFail(err.response.data.message));
      });
  };
};

export const fetchAnimeSingle = (slug) => {
  if (typeof localStorage !== 'undefined') {
    const localJson = JSON.parse(localStorage.getItem('animeList'));

    if (localJson && localJson.time > time().getTime() && localJson.data) {
      const animeInfo = localJson.data.find(anime => anime.slugifyUrl === slug);
      if (animeInfo) {
        return (dispatch) => {
          dispatch(ajaxLoading());
          dispatch(fetchSingle(animeInfo));
        };
      }
    }
  }

  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get(`anime/${slug}`)
      .then((response) => {
        dispatch(fetchSingle(response.data.data));
        dispatch(fetchAnimeListSilent());
      })
      .catch((err) => {
        dispatch(ajaxFail('error'));
      });
  };
};

export const fetchAnimeSearchResults = (type, genres, page) => {
  if (type !== '') {
    type = `&type=${type}`;
  }

  if (genres.length > 0) {
    genres = `&genres=${genres.join(',')}`;
  } else {
    genres = '';
  }

  return (dispatch) => {
    dispatch(ajaxLoading());
    axios.get(`anime/search?page=${page + 1}${type}${genres}`)
      .then((response) => {
        dispatch(fetchSearchResults(response.data));
      })
      .catch((err) => {
        dispatch(ajaxFail(err.response.data.message));
      });
  };
};
