import React from 'react';
import { DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const notification = (props) => {
  if (!props.notification.url) {
    return null;
  }
  return (
    <DropdownItem tag={Link} onClick={e => props.hasBeenSeen(props.notification.id)} to={props.notification.url}>
      {props.notification.message}
    </DropdownItem>
  );
};

export default notification;
