import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import Rollbar from 'rollbar';

import BrowserPages from './BrowserPages';

import FingerprintTrack from './components/Tracking/FingerprintTrack';
import * as actions from './store/actions';

class App extends Component {
  constructor (props) {
    super(props);

    if (process.env.NODE_ENV !== 'development') {
      this.state = {
        rollbar: new Rollbar({
          accessToken: '9fbcd7e884a74d99bb4d73bd134385ea',
          captureUncaught: true,
          captureUnhandledRejections: true
        })
      };
    }
  }

  componentWillMount () {
    this.props.onTryAutoAuth();
    ReactGA.initialize('UA-21409600-1');
  }

  componentDidUpdate () {
    if (this.props.userId) {
      ReactGA.set({ userId: this.props.userId });
    }
  }

  render () {
    ReactGA.pageview(window.location.pathname + window.location.search);

    const wallpaper = document.getElementsByTagName("BODY")[0];
    const windowWidth = window.innerWidth;

    const date = new Date();

    if (wallpaper && windowWidth > 1000 && date.getMonth() === 1 && date.getDate() === 16) {
      if (date.getMonth() === 1 && date.getDate() === 16) {
        wallpaper.style.background = "url('/images/wallpapers/vasario16.jpg') no-repeat center center fixed";
        wallpaper.style.backgroundSize = 'cover';
      } else if (date.getMonth() === 11 && date.getDate() > 23) {
        wallpaper.style.background = "url('/images/wallpapers/xristmas3.jpg') no-repeat center center fixed";
        wallpaper.style.backgroundSize = 'cover';
      } else if ((date.getMonth() === 9 && date.getDate() > 30) || (date.getMonth() === 10 && date.getDate() === 1)) {
        wallpaper.style.background = "url('/images/wallpapers/halloween.jpg') no-repeat center center fixed";
        wallpaper.style.backgroundSize = 'cover';
      }
    }

    return (
      <div>
        <BrowserPages />
        <FingerprintTrack location={this.props.location.pathname} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.userId,
  isAuth: state.auth.token !== null
});

const mapDispatchToProps = dispatch => ({
  onTryAutoAuth: cookies => dispatch(actions.authCheckState(cookies))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
