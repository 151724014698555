import React from 'react';
import PropTypes from 'prop-types';

const ColoredLine = props => (
  <hr
    style={{
      color: props.color,
      backgroundColor: props.color,
      height: props.height,
      marginTop: '0.3rem',
      marginBottom: '0.3rem'
    }}
  />
);

ColoredLine.propTypes = {
  height: PropTypes.number,
  color: PropTypes.string
};

ColoredLine.defaultProps = {
  height: 3,
  color: 'white'
};

export default ColoredLine;
