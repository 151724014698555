import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Spinner from '../../components/UI/Spinner/Spinner';
import Helmet from '../../components/Helmet/Helmet';

import asyncComponent from '../../components/AsyncComponent';

const Comments = asyncComponent(() => import('../../components/Comments/Comments'));

class CMSPage extends Component {
  componentDidMount () {
    this.props.onFetchCMSSingle(this.props.match.params.slug);
  }

  componentDidUpdate (prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.props.onFetchCMSSingle(this.props.match.params.slug);
    }
  }

  render () {
    let content = <Spinner />;
    let title = 'Puslapis';
    let comments = <Spinner />;

    if (!this.props.loading && this.props.cms) {
      title = this.props.cms.title;
      content = <div className="content" dangerouslySetInnerHTML={{ __html: this.props.cms.content }} />;
      comments = <Comments id={this.props.cms.id} type="cms" />;
    }

    return (
      <Row>
        <Helmet title={title} />
        <Col sm={12}>
          <h1 className="headline p3 clearfix">{title}</h1>
        </Col>
        <Col sm={12}>
          {content}
        </Col>
        <Col sm={12}>
          {comments}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.cms.loading,
  cms: state.cms.cms
});

const mapDispatchToProps = dispatch => ({
  onFetchCMSSingle: slug => dispatch(actions.fetchCMSSingle(slug))
});

CMSPage.propTypes = {
  match: PropTypes.any.isRequired,
  cms: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  onFetchCMSSingle: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CMSPage);
