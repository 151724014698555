import React from 'react';
import parser, { Tag } from 'bbcode-to-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Aux from '../../../hoc/Aux/Aux';
import Spoiler from '../../UI/Spoiler/Spoiler';

class YoutubeTag extends Tag {
  toReact () {
    // using this.getContent(true) to get it's inner raw text.
    const attributes = {
      src: this.getContent(true).replace('watch?v=', 'embed/'),
      width: this.params.width || 400,
      height: this.params.height || 225
    };
    return (
      <iframe
        {...attributes}
        style={{
          minWidth: attributes.width, maxWidth: '100%', height: 'auto', minHeight: '300px'
        }}
        frameBorder="0"
        title="youtube video"
        allowFullScreen
      />
    );
  }
}

class AmfLink extends Tag {
  toReact () {
    // using this.getContent(true) to get it's inner raw text.
    const attributes = {
      url: this.params.url || this.getContent(true)
    };
    return (
      <Aux>
        <Link to={attributes.url}>{this.getContent(true)}</Link>
        {' '}
      </Aux>
    );
  }
}

class BreakLineTag extends Tag {
  toReact () {
    return <br />;
  }
}

class ImageTag extends Tag {
  toReact () {
    // using this.getContent(true) to get it's inner raw text.
    const attributes = {
      src: this.getContent(true)
    };
    return (
      <img src={attributes.src} style={{ height: 'auto', maxWidth: '80%', maxHeight: attributes.height }} alt="anime image" />
    );
  }
}

class SpoilerTag extends Tag {
  toReact () {
    return (
      <Spoiler>{parser.toReact(this.getContent())}</Spoiler>
    );
  }
}

class UrlTag extends Tag {
  toReact () {
    const attributes = {
      url: this.params.url
    };
    if (attributes.url) {
      return (
        <a href={attributes.url} target="_blank" rel="noopener noreferrer">{this.getContent(true)}</a>
      );
    }
    return (
      <a href={this.getContent(true)} target="_blank" rel="noopener noreferrer">nuoroda</a>
    );
  }
}

function nl2br (str) {
  return str.map((item, i) => (
    <span key={i++} className="mb10">
      {item}
    </span>
  ));
}

parser.registerTag('spoiler', SpoilerTag); // add new tag
parser.registerTag('youtube', YoutubeTag); // add new tag
parser.registerTag('br', BreakLineTag); // add new tag
parser.registerTag('img', ImageTag); // add new tag
parser.registerTag('url', UrlTag); // add new tag
parser.registerTag('amf', AmfLink); // add new tag

const BBCode = (props) => {
  if (props.children === '' || props.children === null || !props.children) {
    return <Aux>Tusčia</Aux>;
  }

  let children = props.children.replace(':)', '😀').replace(' XD', ' 😆').replace(' xD', ' 😆').replace(':D', '😄');
  children = children.replace(':d', '😄').replace(' :/', '🙁').replace(';)', '😉').replace(':(', '😢');
  children = children.replace(':p', '😛').replace(':P', '😛').replace(':*', '😘').replace(':s', '😟');
  children = children.replace(':S', '😟').replace(':?', '😟').replace(':$', '😳');

  // return <Aux>{nl2br(parser.toReact(children.split('\n').join('[/br]')))}</Aux>;
  return <Aux>{parser.toReact(children.split('\n').join('\n'))}</Aux>;
};

BBCode.propTypes = {
  children: PropTypes.any
};

BBCode.defaultProps = {
  children: null
};

export default BBCode;
