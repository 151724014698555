import axios from 'axios';
import { getCookie } from './shared/utility';

const instance = axios.create({
  //baseURL: process.env.NODE_ENV === 'development' ? 'http://api.amf.local/api/' : 'https://api.amf.lt/api/',
  baseURL: 'https://api.amf.lt/api/',
  headers: {
    Authorization: {
      toString () {
        if (typeof localStorage !== 'undefined') {
          return `Bearer ${localStorage.getItem('token')}`;
        }
        return '';
      }
    },
    Guest: {
      toString () {
        let guest = '';
        if (typeof localStorage !== 'undefined') {
          guest = localStorage.getItem('guest');
        }
        if (guest === null || guest === '') {
          guest = getCookie('guest');
        }
        if (guest === null) {
          guest = '';
        }
        return guest;
      }
    }
  }
});

export default instance;
