import React from 'react';
import {
  Row, Col,
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import UnseenNotifications from '../User/UnseenNotifications/UnseenNotifications';
import SearchBar from './SearchBar';
import Aux from '../../hoc/Aux/Aux';

class NavBar extends React.Component {
  constructor (props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      isOpen: false,
      displaySearch: window.innerWidth > 770
    };
  }

  componentDidMount () {
    this.props.onFetchCMSList();
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  toggleDropDown () {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  close (e) {
    this.setState({
      isOpen: false,
      dropdownOpen: false
    });
  }

  generateDropdownItemList (options) {
    const list = options.map((option, i) => {
      if (option.divider) {
        return <DropdownItem key={i} divider />;
      }
      return <DropdownItem key={i} onClick={e => this.close(e)} tag={Link} to={option.url}>{option.label}</DropdownItem>;
    });
    return <Aux>{list}</Aux>;
  }

  render () {
    let userNav = (
      <NavItem>
        <NavLink tag={Link} to="/login">Prisijungti</NavLink>
      </NavItem>
    );
    let notifications = null;

    if (this.props.isAuth && this.props.username) {
      notifications = <UnseenNotifications />;
      userNav = (
        <Aux>
          <span className="d-none d-sm-block">{notifications}</span>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              {this.props.username}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={e => this.close(e)} tag={Link} to={`/profilis/${this.props.usernameCanonical}`}>Profilis</DropdownItem>
              {this.generateDropdownItemList([
                { url: '/profilio/redagavimas', label: 'Redaguoti' },
                { url: '/profilio/slaptazodis', label: 'Keisti slaptažodį' },
                { url: '/profilio/saugumas', label: 'Saugumas' },
                { divider: true },
                { url: `/anime-sarasas/${this.props.usernameCanonical}`, label: 'Anime sąrašas' },
                { url: '/komentarai', label: 'Mano Komentarai' }
              ])}
              <DropdownItem onClick={e => this.close(e)} tag={Link} to="/pokalbiai">Žinutės</DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/logout">Atsijungti</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Aux>
      );
    }

    let cms = [];

    if (this.props.cms) {
      cms = this.props.cms.map(cms => <DropdownItem key={cms.id} tag={Link} to={`/puslapis/${cms.link}`}>{cms.title}</DropdownItem>);
    }

    let search = null;
    if (this.state.displaySearch) {
      search = <NavItem className="d-none d-sm-block"><SearchBar /></NavItem>;
    }

    return (

      <Navbar color="dark" dark expand="md">
        <Container>

          <Row>
            <Col sm={3} xs={3}>
              <NavbarToggler onClick={this.toggle} />
            </Col>
            <Col xs={8} className="d-block d-sm-none">
              <SearchBar />
            </Col>
            <Col xs={1} className="d-block d-sm-none">{notifications}</Col>
          </Row>

          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="pull-right" navbar>
              <NavItem>
                <NavLink onClick={e => this.close(e)} tag={Link} to="/">Naujienos</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                          Online
                </DropdownToggle>
                <DropdownMenu right>
                  {this.generateDropdownItemList([
                    { url: '/online', label: 'Pilnas sąrašas' },
                    { url: '/anime-paieska', label: 'Ieškoti' },
                    { divider: true },
                    { url: '/online?tipas=subs', label: 'Lietuviškai Subtitruota' },
                    { url: '/online?tipas=audio', label: 'Lietuviškai Igarsinta' },
                    { divider: true },
                    { url: '/dabartiniai-projektai', label: 'Dabartiniai Projektai' }
                  ])}
                </DropdownMenu>
              </UncontrolledDropdown>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                          Anime
                </DropdownToggle>
                <DropdownMenu right>
                  {this.generateDropdownItemList([
                    { url: '/anime', label: 'Pilnas sąrašas' },
                    { url: '/anime-paieska', label: 'Ieškoti' },
                    { url: '/anime-recommendations', label: 'Rekomendacijos' },
                    { divider: true },
                    { url: '/anime?tipas=filmas', label: 'Filmai' },
                    { url: '/anime?tipas=ova', label: 'OVA' },
                    { url: '/anime?tipas=tv', label: 'TV' },
                    { url: '/anime?tipas=special', label: 'Special' }
                  ])}
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink onClick={e => this.close(e)} tag={Link} to="/manga">Manga</NavLink>
              </NavItem>

              <NavItem>
                <NavLink onClick={e => this.close(e)} tag={Link} to="/fan-zona">Fan Zona</NavLink>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                          Daugiau
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={e => this.close(e)} tag={Link} to="/top-anime">Anime Topas</DropdownItem>
                  <DropdownItem onClick={e => this.close(e)} tag={Link} to="/top-movies">Filmų Topas</DropdownItem>
                  <DropdownItem onClick={e => this.close(e)} tag={Link} to="/vartotoju-top">Vartotojų Topas</DropdownItem>
                  {cms}
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink onClick={e => this.close(e)} tag={Link} to="/prisidek">Prisidėk</NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="https://discord.gg/4jTV4hx" rel="noreferrer" target="_blank">
                  <img src="/images/discord.png" style={{ maxHeight: '25px' }} alt="discord" />
                </NavLink>
              </NavItem>

              {search}

            </Nav>
            <Nav className="ml-auto" navbar>
              {userNav}
            </Nav>
          </Collapse>

        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null,
  username: state.auth.username,
  usernameCanonical: state.auth.username_canonical,
  cms: state.cms.list
});

const mapDispatchToProps = dispatch => ({
  onFetchCMSList: () => dispatch(actions.fetchCMSList())
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
