import React, { Component } from 'react';
import {
  Row, Col, Form, FormGroup, Input, Button, UncontrolledAlert
} from 'reactstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from '../../../axios-public';
import styles from './NewRecommendation.module.css';

class NewRecommendation extends Component {
  constructor (props) {
    super(props);
    this.state = {
      list: null,
      recommend: null,
      valid_message: false,
      touched_message: false,
      message: '',
      success: false
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidMount () {
    const localJson = JSON.parse(localStorage.getItem('animeList'));
    if (localJson && localJson.data) {
      const list = localJson.data.filter(obj => obj.id !== this.props.anime.id)
        .map(anime => ({ value: anime.id, label: anime.name }));
      this.setState({ list });
    }
  }

  changeHandler (change) {
    this.setState({ recommend: change.value });
  }

  inputHandler (event) {
    let valid = false;
    if (event.target.value.length > 10) {
      valid = true;
    }
    this.setState({ message: event.target.value, touched_message: true, valid_message: valid });
  }

  submitHandler (event) {
    event.preventDefault();

    if (!this.state.recommend || !this.state.valid_message) {
      this.setState({ touched_message: true });
      return;
    }

    const sCopy = { ...this.state };

    const data = {
      anime_id: this.props.anime.id,
      comment: sCopy.message,
      recommendation_id: sCopy.recommend
    };

    axios.put('anime/recommendations', data)
      .then((res) => {
        this.setState({
          success: true,
          recommend: null,
          valid_message: false,
          touched_message: false,
          message: ''
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render () {
    let form = null;
    let options = [];

    if (this.state.list) {
      options = this.state.list;
    }

    if (this.state.success) {
      form = <UncontrolledAlert color="success">Rekomendacija sėkmingai priimta</UncontrolledAlert>;
    } else if (this.props.isAuth) {
      let size = 6;
      let info = <p>Rekomenduok panašų anime</p>;
      if (this.props.basic) {
        info = null;
        size = 12;
      }
      form = (
        <Form onSubmit={this.submitHandler}>
          <FormGroup row>
            <Col lg={size}>
              {info}
              <Select
                options={options}
                onChange={this.changeHandler}
                id="recommend"
                placeholder="Anime..."
                className={styles.select}
              />
              <Input
                value={this.state.message}
                onChange={this.inputHandler}
                className="mt5"
                type="textarea"
                id="message"
                rows="4"
                invalid={!this.state.valid_message && this.state.touched_message}
                placeholder="Rekomenduoju, nes..."
              />
              <Button className="mt5" color="success" outline size="sm">Rekomenduoju</Button>
            </Col>
            <Col lg={size}>
              <p>Patarimai:</p>
              <ul className="font-size-14">
                <li>Rekomenduokite argumentais kodėl anime panašus arba turėtų patikti.</li>
                <li>Jokių spoilerių.</li>
              </ul>
            </Col>
          </FormGroup>
        </Form>
      );
    }

    return (
      <Row>
        <Col sm={12} className="mt5">
          {form}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null
});

export default connect(mapStateToProps)(NewRecommendation);
