import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Alert, Container } from 'reactstrap';
import './NoticeNar.css';
import { Link } from 'react-router-dom';

class NoticeNar extends Component {
  componentDidMount () {
    this.mounted = true;
  }

  render () {
    if (!this.props.memberUntil) {
      return null;
    }
    if (!moment().isBefore(this.props.memberUntil)) {
      return null;
    }
    if (moment().add(3, 'd').isBefore(this.props.memberUntil)) {
      return null;
    }
    return (
      <Container className="notice-bar">
        <Alert color="warning">
          Papildomos privilegijos baigsis:
          {' '}
          {this.props.memberUntil.substr(0, 10)}
          {'. '}
          <Link to="/parama">Pratęsti</Link>
        </Alert>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  member: state.auth.member,
  memberUntil: state.auth.member_until
});

NoticeNar.propTypes = {
  memberUntil: PropTypes.any
};

NoticeNar.defaultProps = {
  memberUntil: false
};

export default connect(mapStateToProps)(NoticeNar);
