import React, { Component } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image';
import axios from '../../../axios-public';
import Aux from '../../../hoc/Aux/Aux';

class NewReleaseSlider extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anime: null,
      page: 1,
      pages: 1,
      limit: 5,
      total: 0
    };
    this.buttonHandler = this.buttonHandler.bind(this);
  }

  componentDidMount () {
    this.mounted = true;
    this.fetchNewRelease();
    this.interval = setInterval(() => this.fetchNewRelease(), 15 * 60 * 1000); // every 15 min
  }

  componentWillUnmount () {
    this.mounted = false;
    clearInterval(this.interval);
  }

  buttonHandler () {
    const copy = { ...this.state };
    let nextPage = 1;
    if (copy.page + 1 < this.state.pages) {
      nextPage = copy.page + 1;
    }
    this.setState({ page: nextPage });
  }

  fetchNewRelease () {
    const windowWidth = window.innerWidth;
    let limit = this.state.limit;
    if (windowWidth <= 577) {
      limit = 2;
    }

    axios.get('online/translation-slider')
      .then((res) => {
        const pages = Math.floor(res.data.data.length / limit);

        if (this.mounted) {
          this.setState({
            anime: res.data.data, total: res.data.data.length, pages, limit
          });
        }
      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  render () {
    const content = [];
    if (this.state.anime) {
      let i = (this.state.limit * (this.state.page - 1));

      for (const index in this.state.anime) {
        if (index >= i && i < (this.state.limit * this.state.page)) {
          const anime = this.state.anime[index];

          let type = 'subs';
          if (anime.type !== 'subtitruota') {
            type = 'audio';
          }

          const picture = anime.picture_url.replace('anime/', 'anime/thumb/');

          content.push(
            <Col key={index} className="release-box">
              <Link to={`/online/${anime.slugifyUrl}/ep${anime.ep}-${type}`}>
                <div className="ncontainer">
                  <Image
                    className="image"
                    src={picture}
                    srcSet={[picture.replace('.webp', '.jpg')]}
                    alt={anime.name.replace(/[^0-9a-zA-Z ,]/g, '')}
                  />
                  <div className="middle">
                    <div className="text">
                      <h5>{anime.name}</h5>
                      {`${anime.ep} dalis, lt ${anime.type}`}
                      {' '}
                      <br />
                      <br />
                      {anime.genres}
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          );
          i += 1;
        }
      }
    }
    return (
      <Aux>
        <Row>
          <Col sm={12}>
            <h5 className="headline p3 clearfix">
              Nauji Vertimai
              <Button size="xs" className="float-right" onClick={this.buttonHandler}>Daugiau</Button>
            </h5>
          </Col>
        </Row>
        <Row className="newReleaseSlider" style={{ paddingLeft: '15px' }}>
          {content}
        </Row>
      </Aux>
    );
  }
}

export default NewReleaseSlider;
