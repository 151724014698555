import { Component } from 'react';
import { connect } from 'react-redux';
import Fingerprint2 from 'fingerprintjs2';
import UAParser from 'ua-parser-js';
import { withCookies } from 'react-cookie';
import ReactGA from 'react-ga';
import axios from '../../axios-public';

const pushData = (result, components, cookies) => {
  ReactGA.ga((tracker) => {
    const data = {
      result,
      components,
      comment: null,
      cindirela: cookies.get('_s12z2'),
      cid: tracker.get('clientId'),
      uid: tracker.get('userId'),
      ga: tracker.get('_ga'),
      guest: cookies.get('guest'),
      version: '1.5.1'
    };
    if (typeof localStorage !== 'undefined') {
      data.comment = localStorage.getItem('__zamd');
    }
    axios.put('stats', data);
  });
};

class FingerprintTrack extends Component {
  constructor (props) {
    super(props);

    const now = new Date();

    if (!this.props.cookies.get('_s12z2')) {
      this.props.cookies.set('_s12z2', now.getTime(), { path: '/' });
    }
  }

  componentDidMount () {
    this.finger();
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.finger();
    }
  }

  finger () {
    const cookies = this.props.cookies;

    Fingerprint2.get({
      preprocessor (key, value) {
        if (key === 'userAgent') {
          const parser = new UAParser(value);
          let userAgentMinusVersion = `${parser.getOS().name} ${parser.getBrowser().name}`;

          if (parser.getDevice().type !== undefined) {
            userAgentMinusVersion += ` ${parser.getDevice().type}`;
          }
          if (parser.getBrowser().major !== undefined) {
            userAgentMinusVersion += ` ${parser.getBrowser().major}`;
          }
          return userAgentMinusVersion;
        } if (key === 'fonts') {
          const exclude = [
            'Arial', 'Arial Black', 'Arial Narrow', 'Courier', 'Courier New', 'Comic Sans MS', 'Geneva', 'Georgia',
            'Times New Roman', 'Time', 'Verdana', 'Wingdings', 'Wingdings 2', 'Wingdings 3', 'Helvetica', 'Microsoft Sans Serif',
            'Arial Hebrew', 'Arial Rounded MT Bold', 'Arial Unicode MS'
          ];

          exclude.forEach((entry) => {
            const index = value.indexOf(entry);
            value = value.slice(index);
          });

          return value;
        }
        return value;
      }
    }, (components) => {
      // user_agent component will contain string processed with our function. For example: Windows Chrome

      const values = components.map(component => component.value);
      const murmur = Fingerprint2.x64hash128(values.join(''), 31);

      if (typeof localStorage !== 'undefined' && localStorage.getItem('__zamd') === null) {
        localStorage.setItem('__zamd', murmur);
      }

      pushData(murmur, components, cookies);
    });
  }

  render () {
    return null;
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.token !== null
});

export default connect(mapStateToProps)(withCookies(FingerprintTrack));
