import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Image from 'react-graceful-image';
import Aux from '../../../hoc/Aux/Aux';

const AnimeRecommandationItem = (props) => {
  const anime = props.recommendation.anime;
  const recommendation = props.recommendation.recommendation;
  const user = props.user;

  const picture = anime.image_url.replace('anime/', 'anime/thumb/');

  return (
    <Aux>
      <Col lg={2} md={3} sm={5} xs={5} className="mt10">
        <Link to={anime.url}>
          <Image src={picture} srcSet={[picture.replace('.webp', '.jpg')]} alt={anime.name} />
        </Link>
      </Col>
      <Col lg={2} md={3} sm={7} xs={7} className="mt10 font-size-12">
        <p>
          {'Jeigu patiko '}
          <Link to={anime.url}>{anime.name}</Link>
          {', tada rekomenduoju pažiūrėti '}
          <Link to={recommendation.url}>{recommendation.name}</Link>

        </p>
        <p><Link to={user.profile_url}>{user.username}</Link></p>
      </Col>
    </Aux>
  );
};

AnimeRecommandationItem.propTypes = {
  recommendation: PropTypes.any.isRequired,
  user: PropTypes.any.isRequired
};

export default AnimeRecommandationItem;
