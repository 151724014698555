import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import NavBar from '../../components/NavBar/NavBar';
import Spinner from '../../components/UI/Spinner/Spinner';
import NoticeNar from '../../components/UI/NoticeNar/NoticeNar';
import Footer from './Footer/Footer';

const DefaultSidebar = lazy(() => import('../../components/Sidebar/Default/DefaultSidebar'));
const OnlineSidebar = lazy(() => import('../../components/Sidebar/Online/OnlineSidebar'));

const DefaultLayout = ({ component: Component, ...rest }) => {
  let sidebar = <DefaultSidebar />;
  if (rest.sidebar === 'online') {
    sidebar = <OnlineSidebar params={rest.computedMatch.params} />;
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        <div id="wallpaper">
          <NavBar />
          <Container className="mt5 main-content">
            <Row style={{ paddingTop: '5px' }}>
              <Col lg={9} md={8} sm={7} className="dark-bg text-color" style={{ paddingBottom: '10px' }}>
                <Component {...matchProps} />
              </Col>
              <Col lg={3} md={4} sm={5}>
                <Col sm={12} className="dark-bg text-color" style={{ paddingBottom: '10px' }}>
                  <React.Suspense fallback={<Spinner />}>{sidebar}</React.Suspense>
                </Col>
              </Col>
            </Row>
          </Container>
          <Footer />
          <NoticeNar />
        </div>
      )}
    />
  );
};

export default DefaultLayout;
