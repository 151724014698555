import React from 'react';
import Helmet from 'react-helmet';

const helmet = (props) => {
  const suffix = ' | Anime Online LT';
  const prefix = 'Anime Online LT - ';
  let title = props.title + suffix;
  if (props.prefix) {
    title = prefix + props.title;
  }

  let description = null;
  let description2 = null;
  if (props.description) {
    const metaDescription = props.description.replace(/<(?:.|\n)*?>/gm, '').slice(0, 160);
    description = <meta name="description" content={metaDescription} />;
    description2 = <meta name="og:description" content={metaDescription} />;
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="og:title" content={title} />
      {description}
      {description2}
      <meta property="og:site_name" content="Anime Online Lt" />
      {props.children}
    </Helmet>
  );
};

export default helmet;
