import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import NavBar from '../../components/NavBar/NavBar';
import Footer from './Footer/Footer';
import Aux from '../Aux/Aux';

const OnePageLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <Aux>
        <NavBar />
        <Container style={{ marginTop: '5px' }}>
          <Row style={{ paddingTop: '5px' }}>
            <Col sm={12} className="dark-bg text-color" style={{ paddingBottom: '10px' }}>
              <Component {...matchProps} />
            </Col>
          </Row>
        </Container>
        <Footer />
      </Aux>
    )}
  />
);

export default OnePageLayout;
