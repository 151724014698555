import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';
import { withRouter, Redirect } from 'react-router-dom';
import { FaVideo, FaUserAlt } from 'react-icons/fa';

class SearchBar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      dropdownOpen: false, type: 'video', search: '', hit: false
    };
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search);
    this.setState({ search: params.get('name'), hit: false });
  }

  onInputChange (e) {
    this.setState({ search: e.target.value });
  }

  toggleDropDown () {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  changeType (event, type) {
    this.setState({ type });
  }

  handleKeyPress (e) {
    if (e.key === 'Enter' && this.state.type === 'video') {
      const val = e.target.value ? e.target.value : '';
      this.setState({ search: val, hit: true });
    } else if (e.key === 'Enter' && e.target.value.length > 2 && this.state.type === 'user') {
      this.props.history.push({
        pathname: '/paieska',
        search: `?type=user&q=${e.target.value}`
      });
    }
  }

  render () {
    let type = <FaVideo />;
    let redirect = null;
    if (this.state.type === 'user') {
      type = <FaUserAlt />;
    }

    if ((this.state.search || this.state.search === '') && this.state.hit) {
      const endpoint = this.state.search ? `?name=${this.state.search}` : '';
      redirect = <Redirect to={`/anime-paieska${endpoint}`} />;
    }

    let searchFieldValue = '';
    if (this.state.search) {
      searchFieldValue = this.state.search;
    }

    return (
      <InputGroup style={{ marginTop: '7px' }}>
        {redirect}
        <Input placeholder="Ieškoti..." bsSize="sm" value={searchFieldValue} onChange={this.onInputChange} onKeyPress={this.handleKeyPress} />
        <InputGroupButtonDropdown addonType="append" isOpen={this.state.dropdownOpen} toggle={this.toggleDropDown}>
          <DropdownToggle caret size="sm">
            {type}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="color-white" style={{ color: '#fff' }} onClick={e => this.changeType(e, 'video')}>
              <FaVideo />
              {' Anime'}
            </DropdownItem>
            <DropdownItem className="color-white" style={{ color: '#fff' }} onClick={e => this.changeType(e, 'user')}>
              <FaUserAlt />
              {' Vartotojas'}
            </DropdownItem>
          </DropdownMenu>
        </InputGroupButtonDropdown>
      </InputGroup>
    );
  }
}

SearchBar.propTypes = {
  location: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired
};

export default withRouter(SearchBar);
